import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';
import { Pill } from '@shared/ui/Pill';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationCardItemProps } from './types';

type ApplicationCardSelectableItemProps = ApplicationCardItemProps & {
  isChecked: boolean;
  onChange: (id: number) => void;
};

function useApplicationCardSelectableItemState({
  name,
  category,
  amount,
  unit,
  id,
  isChecked,
  onChange,
}: ApplicationCardSelectableItemProps) {
  const { t } = useTranslation();
  const itemClickHandler = useCallback(() => {
    onChange(id);
  }, [id]);

  return {
    itemClickHandler,
    isChecked,
    categoryName: t(category.name),
    itemName: t(name),
    amountWithUnit: `${amount}${t(unit || '')}`,
  } as const;
}

export const ApplicationCardSelectableItem = (props: ApplicationCardSelectableItemProps) => {
  const { isChecked, itemClickHandler, categoryName, itemName, amountWithUnit } =
    useApplicationCardSelectableItemState(props);

  return (
    <div className="flex cursor-pointer mt-2" onClick={itemClickHandler}>
      <CheckboxNotFormField name="test" isChecked={isChecked} className="w-[24px] h-[24px] mr-3 mt-[4px] shrink-0" />
      <div className="flex flex-wrap gap-x-2 gap-y-2">
        <Pill className="bg-granny-apple shrink-0">{categoryName}</Pill>
        <Pill className="bg-granny-apple">{itemName}</Pill>
        <Pill className="bg-yellow-apple shrink-0">{amountWithUnit}</Pill>
      </div>
    </div>
  );
};
