import { ArrowDownIcon } from '@shared/icons/arrow-down';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AccordionBasicProps {
  label?: string;
  isOpen?: boolean;
  onClick?: any;
  children: React.ReactNode;
  className?: string;
}

export const AccordionBasic: React.FC<AccordionBasicProps> = (props) => {
  const { label = '', isOpen = false, onClick, children, className } = props;
  const { t } = useTranslation();

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          'flex items-center justify-between p-4 bg-solitude hover:bg-alice-blue active:bg-gainsboro transition-colors rounded-xxs',
        )}
        onClick={onClick}
        role="button"
      >
        <span className="mr-4">{t(label)}</span>
        <ArrowDownIcon className={clsx('w-3 h-2 shrink-0 transition-transform', isOpen && 'rotate-180')} />
      </div>
      <div className={clsx('px-4 py-6', isOpen ? '' : 'hidden')}>{children}</div>
    </div>
  );
};
