import React from 'react';

import { ApplicationView } from '@shared/enums';
import { useRemoveApplicationItem } from '@shared/hooks/useRemoveApplicationItem';
import { isDefaultView, isOnlineMode } from '@shared/utils/applications';

import { ApplicationCardFullItem } from './ApplicationCardFullItem';
import { ApplicationCardItemDetails } from './ApplicationCardItemDetails';
import { ApplicationCardRemovableItem } from './ApplicationCardRemovableItem';
import { ApplicationCardItemsTitle } from './ApplicationCardItemsTitle';

import { Customer, ApplicationItem } from './types';
import { useVolunteerMode } from '../volunteer/modals/hooks/useVolunteerMode';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  items: ApplicationItem[];
  view: ApplicationView;
  customer: Customer;
};

function useVerificationApplicationCardItemsState({ customer, view, items }: Props) {
  const onRemoveHandler = useRemoveApplicationItem();

  const [mode] = useVolunteerMode();
  const isOffline = !isOnlineMode(mode);

  const ApplicationCardItem = isDefaultView(view)
    ? isOffline
      ? ApplicationCardRemovableItem
      : ApplicationCardFullItem
    : ApplicationCardItemDetails;

  return { view, items, ApplicationCardItem, onRemoveHandler } as const;
}
export const VerificationApplicationCardItems = (props: Props) => {
  const { view, items, ApplicationCardItem, onRemoveHandler } = useVerificationApplicationCardItemsState(props);

  return (
    <div className="lg:col-span-3">
      <ApplicationCardItemsTitle view={view} />
      {items.map((item) => (
        <ApplicationCardItem onRemove={onRemoveHandler} key={item.id} {...item} needConfirm />
      ))}
    </div>
  );
};
