import React from 'react';
import clsx from 'clsx';

import { useToggleState } from '@shared/hooks/useToggleState';
import { Element } from '@shared/interfaces/element.interface';

import { ApplicationState } from '@shared/enums';
import { Shop } from '@hooks/useIntegrationConfigs';

import { useActiveTab } from '../modals/hooks/useActiveTab';
import { useUnusedCertificates } from '../modals/hooks/useUnusedCertificates';

import { ModalBuyCertificates } from '../modals/ModalBuyCertificates';
import { ModalMyCertificates } from '../modals/ModalMyCertificates';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';

import { CertificatesDropdown } from '../dropdowns/CertificatesDropdown';

interface CertificatesButtonProps extends Element {}

const useComponentState = ({ className }: CertificatesButtonProps) => {
  const [_, setActiveTab] = useActiveTab();

  const { bought } = useUnusedCertificates();

  const [isBuyCertificatesModalVisible, toggleIsBuyCertificatesModalVisible] = useToggleState(false);
  const [isMyCertificatesModalVisible, toggleIsMyCertificatesModalVisible] = useToggleState(false);

  const { onCategoryChange, onShopFilterChange } = useFiltersHandlers();

  const showBuyCertificatesModal = () => {
    if (!isBuyCertificatesModalVisible) {
      toggleIsBuyCertificatesModalVisible();
    }
  };

  const showMyCertificatesModal = () => {
    if (!isMyCertificatesModalVisible) {
      toggleIsMyCertificatesModalVisible();
    }
  };

  const onApproveMyCertificatesModal = (shop: Shop) => {
    onShopFilterChange(shop.name);
    onCategoryChange(shop.category);

    toggleIsMyCertificatesModalVisible();

    setActiveTab(ApplicationState.New);
  };

  const onRejectMyCertificatesModal = () => {
    toggleIsMyCertificatesModalVisible();

    toggleIsBuyCertificatesModalVisible();
  };

  const isAnyModalVisible = isMyCertificatesModalVisible || isBuyCertificatesModalVisible;

  return {
    className,

    isMyCertificatesDisabled: bought.length <= 0,
    isAnyModalVisible,

    myCertificatesModalProps: {
      isVisible: isMyCertificatesModalVisible,
      onApprove: onApproveMyCertificatesModal,
      onClose: toggleIsMyCertificatesModalVisible,
      onReject: onRejectMyCertificatesModal,
    },
    buyCertificatesModalProps: {
      isVisible: isBuyCertificatesModalVisible,
      onApprove: toggleIsBuyCertificatesModalVisible,
      onClose: toggleIsBuyCertificatesModalVisible,
    },
    actions: {
      showBuyCertificatesModal,
      showMyCertificatesModal,
    },
  };
};

export const CertificatesButton: React.FC<CertificatesButtonProps> = (props) => {
  const {
    isMyCertificatesDisabled,
    isAnyModalVisible,

    myCertificatesModalProps,
    buyCertificatesModalProps,

    actions,

    className,
  } = useComponentState(props);

  return (
    /**
     * `isAnyModalVisible` we have a bad modal implementation, so when
     * our modal is visible we have problems with header's zIndex. We use this hack
     * to change zIndex, when any modal is visible.
     */
    <div className={clsx('z-10', isAnyModalVisible && 'z-ui', className)}>
      <CertificatesDropdown
        isMyCertificatesDisabled={isMyCertificatesDisabled}
        onBuyCertificatesClick={actions.showBuyCertificatesModal}
        onMyCertificatesClick={actions.showMyCertificatesModal}
      />

      <ModalBuyCertificates {...buyCertificatesModalProps} />
      <ModalMyCertificates {...myCertificatesModalProps} />
    </div>
  );
};
