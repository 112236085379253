import { useNavigate } from '@hooks/useNavigate';
import { HowToHelpType, Routes, VolunteerMode } from '@shared/enums';
import { useToggleState } from '@shared/hooks/useToggleState';
import { useUser } from '@shared/hooks/useUser';
import { Button } from '@shared/ui/buttons/Button';
import { useAppSelector } from '@store/hooks';
import { isAnyApplicationSelected, selectSelectedApplications } from '@store/slices/applications/selectors';
import { ApproveContactRequestModal } from '@widgets/customer/profile/approve-contact-request-modal';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCanUserTakeApplication } from './hooks/useCanUserTakeApplication';
import { ApplicationConfirmModal } from './modals/ApplicationConfirmModal';
import { ModalGratitudeHandToHand } from './modals/ModalGratitudeHandToHand';
import { ModalHandHelpGuest } from './modals/ModalHandHelpGuest';
import { ModalPhoneIsRequired } from './modals/ModalPhoneIsRequired';
import { useActiveTab } from './modals/hooks/useActiveTab';
import { useIsVisibleGuard } from './modals/hooks/useIsVisibleGuard';
import { SuccessHandToHandButtonToken } from './SuccessHandToHandButtonToken';
import { useCreateContactRequestMutation } from '@store/api/contact-request.api';
import { useContactRequestData } from '@widgets/profile/useContactRequestData';
import { useNotify } from '@shared/hooks/useNotify';
import { useDispatch } from 'react-redux';
import { applicationsActions } from '@store/slices/applications/slice';

const useApproveContactRequestModal = ({
  toggleIsApproveModalVisible,
}: {
  toggleIsApproveModalVisible: () => void;
}) => {
  const selectedApplications = useAppSelector(selectSelectedApplications);
  const notify = useNotify();
  const [onApproveLoading, setIsHandlerLoading] = useState(false);
  const [createContactRequest] = useCreateContactRequestMutation();
  const { mySendContactRequests } = useContactRequestData();

  const dispath = useDispatch();
  const allCustomers = selectedApplications.map((app) => app.customer.id).filter(Boolean);

  const customers = [...new Set(allCustomers)];
  const sendCustomers = mySendContactRequests?.items.map((cr) => cr.userInNeed.id) || [];
  const customersToSend = customers.filter((id) => !sendCustomers.includes(id));

  const onApproveHandler = async (message: string) => {
    if (!message) return;
    try {
      setIsHandlerLoading(true);

      await Promise.all(
        customersToSend.map((id) =>
          createContactRequest({ userInNeedId: id, message })
            .unwrap()
            .catch((err) => notify(err.message || 'notifications.profile-updated', { autoClose: 2000 })),
        ),
      );
      toggleIsApproveModalVisible();
      setIsHandlerLoading(false);
      dispath(applicationsActions.removeAll());
    } catch (e: unknown) {
      // @ts-ignore
      if (e.message) {
        // @ts-ignore
        notify(e.message, { autoClose: 2000 });
      }
    }
  };
  const onApproveDisabled = false;
  return { onApproveHandler, onApproveDisabled, onApproveLoading };
};

type Props = React.HTMLAttributes<HTMLDivElement>;

function useWidgetState(_props: Props) {
  const [isVisible, toggleIsVisible] = useToggleState(false);
  const [isSuccessModalVisible, toggleSuccessModalVisible] = useToggleState(false);
  const [isApproveContactRequestModalVisible, setApproveContactRequestModalVisible] = useState(false);
  const [isHandHelpModalVisible, toggleIsHandHelpModalVisible] = useToggleState(false);

  const dispath = useDispatch();

  const isButtonVisible = useAppSelector(isAnyApplicationSelected);
  const [_, setActiveTab] = useActiveTab();

  const [user] = useUser();
  const navigate = useNavigate();

  const [canUserTakeApplication] = useCanUserTakeApplication();
  const [isPhoneModalVisible, toggleIsPhoneModalVisible] = useToggleState(false);

  const handleOnClose = useCallback((successToken?: SuccessHandToHandButtonToken) => {
    toggleIsVisible();
    if (successToken === SuccessHandToHandButtonToken.SendContactRequest) {
      setApproveContactRequestModalVisible(true);
    }
    if (successToken === SuccessHandToHandButtonToken.Info) {
      toggleSuccessModalVisible();
    }
  }, []);

  const handleOnSuccessApprove = useCallback(() => {
    toggleSuccessModalVisible();

    setActiveTab('in_progress');
  }, []);

  const { onApproveHandler, onApproveDisabled, onApproveLoading } = useApproveContactRequestModal({
    toggleIsApproveModalVisible: () => {
      setApproveContactRequestModalVisible((isVisible: boolean) => {
        if (isVisible) {
          toggleSuccessModalVisible();
          return false;
        }

        return true;
      });
    },
  });

  const handleCloseContactRequestModal = () => {
    setApproveContactRequestModalVisible(false);
    dispath(applicationsActions.removeAll());
  };
  return {
    modalProps: { isVisible, onClose: handleOnClose },
    approveContactRequestModalProps: {
      isVisible: isApproveContactRequestModalVisible,
      onClose: handleCloseContactRequestModal,
      onApproveHandler: onApproveHandler,
      disabled: onApproveDisabled,
      isLoading: onApproveLoading,
    },
    toggleIsVisible,
    isButtonVisible,
    handHelpGuestModalProps: {
      isVisible: isHandHelpModalVisible,
      onClose: () => {
        toggleIsHandHelpModalVisible();
      },
      onSecondaryBtnClick: () => {
        toggleIsHandHelpModalVisible();
        navigate(Routes.VolunteerSignUp);
      },
      onApprove: () => {
        toggleIsHandHelpModalVisible();
        navigate(Routes.VolunteerCabinet + '?category=online-food');
      },
    },
    gratitudeModalProps: {
      isVisible: isSuccessModalVisible,
      onClose: toggleSuccessModalVisible,
      onApprove: handleOnSuccessApprove,
    },
    phoneIsRequiredModalProps: {
      isVisible: isPhoneModalVisible,
      onClose: toggleIsPhoneModalVisible,
      onApprove: () => {
        toggleIsPhoneModalVisible();

        navigate(Routes.Profile);
      },
    },
    onHelpButtonClick: () => {
      if (user?.isMember) {
        if (canUserTakeApplication) {
          toggleIsVisible();
        } else {
          toggleIsPhoneModalVisible();
        }
      } else {
        toggleIsHandHelpModalVisible();
      }
    },
  };
}
export const HelpToHandsButton = ({ className }: Props) => {
  const {
    modalProps,
    phoneIsRequiredModalProps,
    handHelpGuestModalProps,
    onHelpButtonClick,
    approveContactRequestModalProps,
    isButtonVisible,
    gratitudeModalProps,
  } = useWidgetState({
    className,
  });

  const { t } = useTranslation();

  const isVisible = useIsVisibleGuard({
    mode: [VolunteerMode.Offline],
    category: [
      HowToHelpType.HandToHand,
      HowToHelpType.Easter,
      HowToHelpType.ChildrenProtected,
      HowToHelpType.ChildrenToy,
    ],
  });
  if (!isVisible) return null;

  return (
    <>
      {isButtonVisible && (
        <Button variant="rich-blue" as="button" className={className} onClick={onHelpButtonClick}>
          {t('give-help')}
        </Button>
      )}
      <ApplicationConfirmModal {...modalProps} />
      <ModalGratitudeHandToHand {...gratitudeModalProps} />
      <ModalPhoneIsRequired {...phoneIsRequiredModalProps} />
      <ApproveContactRequestModal {...approveContactRequestModalProps} />
      <ModalHandHelpGuest {...handHelpGuestModalProps} />
    </>
  );
};
