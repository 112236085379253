import { VolunteerMode } from '@shared/enums';

export const volunteerHowToHelpOptions = [
  {
    isActive: (mode: VolunteerMode) => mode === VolunteerMode.Offline,
    eventKey: VolunteerMode.Offline,
    label: 'from-hand-to-hand',
    icon: {
      name: 'help-hands',
      className: 'w-[31px] h-auto',
    },
  },
  {
    isActive: (mode: VolunteerMode) => mode === VolunteerMode.Online,
    eventKey: VolunteerMode.Online,
    label: 'online',
    icon: {
      name: 'help-online',
      className: 'w-[26px] h-auto',
    },
  },
];
