import { useCancelApplicationItemsMutation } from '@store/api/applications.api';
import { useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/slices/auth';

import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';

export function useRemoveApplicationItem() {
  const user = useAppSelector(selectAuthUser);
  const [cancelApplicationItems] = useCancelApplicationItemsMutation();
  const onRemoveHandler = (id: number) => {
    if (user) {
      cancelApplicationItems({ target: ApplicationsTotalTarget.Volunteer, itemIds: [id] });
    }
  };

  return onRemoveHandler;
}
