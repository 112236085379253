import { VolunteerMode } from '@shared/enums';
import { RootState } from '@store/index';

const getVolunteerState = (state: RootState) => state.volunteer;

/**
 * Selectors
 */
export const selectMode = (state: RootState) => getVolunteerState(state).mode || VolunteerMode.Online;

export const selectActiveTab = (state: RootState) => getVolunteerState(state).tab;

export const selectFilters = (state: RootState) => getVolunteerState(state).filters;

export const selectPagination = (state: RootState) => getVolunteerState(state).pagination;
