import { HowToHelpType, VolunteerMode } from '@shared/enums';
import { ChipChoice } from '@shared/ui/chips/ChipChoice';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLanguage } from '@hooks/useLanguage';
import { useFilters } from '../modals/hooks/useFilters';
import { useVolunteerMode } from '../modals/hooks/useVolunteerMode';
import { useVolunteerSetHowToHelp } from '../useVolunteerSetHowToHelp';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import { volunteerHowToHelpOptions } from '@values/volunteer-how-to-help';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import { useChipLists } from './useChipLists';

export const VolunteerHowToHelpChoose = () => {
  useVolunteerSetHowToHelp();

  const language = useLanguage();
  const { category } = useFilters();

  const { chipList, offlineChipList } = useChipLists();
  const imagesOprimized = useGetIconsOptimized();
  const params = useSearchParams();

  const paramsCategory = params?.get('category');

  const [mode, setMode] = useVolunteerMode();

  useEffect(() => {
    if (mode === VolunteerMode.Offline) {
      const onlineCategory = chipList.find((c) => c.eventKey === paramsCategory);
      if (onlineCategory || paramsCategory === '') {
        setMode(VolunteerMode.Online);
      }
      return;
    }

    if (mode === VolunteerMode.Online) {
      const offlineCategory = offlineChipList.find((c) => c.eventKey === paramsCategory);
      if (offlineCategory) {
        setMode(VolunteerMode.Offline);
      }
      return;
    }
  }, [paramsCategory, chipList.length, offlineChipList.length]);

  const handleHowToHelpChange = useCallback((eventKey: VolunteerMode) => {
    setMode(eventKey);
  }, []);

  const volunteerHowToHelpOptionsWithImages = useMemo(() => {
    return volunteerHowToHelpOptions.map((item) => {
      const image = imagesOprimized.find((v) => v.name === item.icon.name);
      const iconUpdated = {
        // @ts-ignore
        image: image.content,
        className: item.icon.className,
      };
      return {
        ...item,
        icon: iconUpdated,
      };
    });
  }, [imagesOprimized]);

  const items = useMemo(() => {
    const filteredOptions =
      language === 'uk'
        ? volunteerHowToHelpOptionsWithImages
        : volunteerHowToHelpOptionsWithImages.filter((v) => v.eventKey !== VolunteerMode.Offline);

    return filteredOptions.map((item) => ({
      ...item,
      isActive: item.isActive(mode),
    }));
  }, [category, mode, language]);

  return (
    <div className="flex space-x-3">
      {items.map((item) => (
        <ChipChoice
          key={item.eventKey}
          label={item.label}
          eventKey={item.eventKey as VolunteerMode}
          isActive={item.isActive}
          icon={item.icon}
          iconClassName={(item as any).iconClassName}
          onClick={handleHowToHelpChange}
        />
      ))}
    </div>
  );
};
