import React from 'react';
import { useTranslation } from 'react-i18next';
import { Banner } from '@shared/ui/banner';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useFilters } from './modals/hooks/useFilters';

export const UnusedCertificatesBanner = () => {
  const { t } = useTranslation();
  const iconPointUpOptimized = useGetIconOptimized('point-up');

  const { category } = useFilters();
  const certificates = [];
  // const [_, certificates] = useIntegrationAndUnusedCertificates(category as OnlineHelp);

  if (certificates.length > 0) {
    return (
      <Banner className="mb-10 !justify-center border border-1 border-x-black-7">
        <div className="flex flex-row items-center space-x-5">
          <GatsbyImage image={getImage(iconPointUpOptimized)!} alt="icon" className="w-[30px] h-[30px]" />
          <span>{t('have-unused-sum')}</span>
        </div>
      </Banner>
    );
  }

  return null;
};
