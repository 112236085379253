import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyGetApplicationsFiltersQuery } from '@store/api/applications.api';

import { useFilters } from './useFilters';
import { useFiltersHandlers } from './useFiltersHandlers';

interface FilterRegion {
  id: number;
  name: string;
}

interface FilterLocality {
  id: number;
  name: string;
  regionId: number;
}

interface FilterCategory {
  id: number;
  name: string;
}

interface FilterCategoryItem {
  id: number;
  name: string;
  categoryId: number;
}

interface Filter {
  regions: FilterRegion[];
  localities: FilterLocality[];
  categories: FilterCategory[];
  categoryItems: FilterCategoryItem[];
  applicantCategories: FilterCategory[];
}

export function useFiltersOptions() {
  const filters = useFilters();
  // const filtersHandlers = useFiltersHandlers();

  const [refetch] = useLazyGetApplicationsFiltersQuery();

  /**
   * API response
   */
  const [data, setData] = useState<{ regions: any[]; categories: any[]; groups: any[] }>({
    regions: [],
    categories: [],
    groups: [],
  });
  const getApplicationFilters = useCallback(async () => {
    const { data = { regions: [], categories: [], groups: [] } } = await refetch(filters);

    setData(data);
  }, [filters, refetch]);

  /**
   * First fetching
   */
  useEffect(() => {
    (async () => {
      await getApplicationFilters();
    })();
  }, [filters]);

  /**
   * Filters options
   */
  const filtersOptions: Filter = useMemo<Filter>(() => {
    const { regions = [], categories, groups } = data;
    const { regionId, categoryIds } = filters;

    const localities = regions
      .flatMap((region: any) => (region.id === regionId ? region.localities : []))
      .filter((item: any) => item.id);

    const categoryItems = categories
      .filter((category: any) => categoryIds.includes(category.id))
      .flatMap((category: any) => category.items.map((item: any) => ({ ...item, categoryId: category.id })));

    return {
      regions,
      localities,
      categories,
      categoryItems,
      applicantCategories: groups,
    };
  }, [filters, data]);

  useEffect(() => {
    // TODO: don't make refetch on this one
    // if (filters.categoryIds.length <= 0 && filtersOptions.categories.length > 0) {
    //   filtersHandlers.onCategoryIdsChange(filtersOptions.categories.map((item) => item.id));
    // }
    // if (filters.categoryItemIds.length <= 0 && filtersOptions.categoryItems.length > 0) {
    //   filtersHandlers.onCategoryItemsIdsChange(filtersOptions.categoryItems.map((item) => item.id));
    // }
  }, [data]);

  return filtersOptions;
}
