import { Shop } from '@hooks/useIntegrationConfigs';
import { CertificateAmount } from '@store/api/types';

import { useLanguage } from '@hooks/useLanguage';

import { redirect } from '@shared/utils/path';
import { useCreateBulkPurchasesMutation } from '@store/api/purchases.api';

export const useBuyCertificates = (shop: Shop | null, amounts: CertificateAmount[]) => {
  const [createBulkPurchases, { isLoading }] = useCreateBulkPurchasesMutation();
  const language = useLanguage();

  const buyCertificates = async () => {
    if (!shop) {
      return;
    }

    const params = {
      type: shop.name,
      locale: language === 'uk' ? 'ua' : 'en',
      purchases: amounts
        .filter((amount) => amount.count > 0)
        .map((amount) => ({ amount: amount.value, count: amount.count })),
    };

    try {
      const { redirectUrl } = await createBulkPurchases(params).unwrap();

      /**
       * We need to make redirect to payment in order to finish purchase
       */
      redirect(redirectUrl);
    } catch (err) {
      /**
       * TODO: error handling
       */
      console.log(err);
    }
  };

  return [buyCertificates, { isLoading }] as const;
};
