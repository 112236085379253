import { useLanguage } from '@hooks/useLanguage';
import { localeCurrencyMap } from '@shared/constants';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import fbTrack from '@shared/utils/fbTrack';
import { useEffect } from 'react';
import { ModalVisibleEvent } from './modals/hooks/useModalProps';

export function useFBTrackSuccessPayment() {
  const language = useLanguage();
  const searchParams = useSearchParams();
  const eventParam = searchParams!.get('event');
  const amountParam = searchParams!.get('amount');

  useEffect(() => {
    if (eventParam === ModalVisibleEvent.HelpOnlineSuccess && amountParam) {
      fbTrack('Purchase', { currency: localeCurrencyMap[language], value: amountParam });
    }
  }, [eventParam, amountParam, language]);
}
