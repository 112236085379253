import React from 'react';
import clsx from 'clsx';

interface NumberCounterProps {
  value: number;

  onIncrease: () => void;

  onDecrease: () => void;
}

export const NumberCounter: React.FC<NumberCounterProps> = ({ value, onIncrease, onDecrease }) => {
  return (
    <div className="flex items-center">
      <span className="px-2 text-[26px] cursor-pointer" onClick={onDecrease}>
        &#8722;
      </span>

      <span className={clsx('block min-w-[40px] text-xs text-center', value < 1 && 'text-black-50')}>{value}</span>

      <span className="px-2 text-[26px] cursor-pointer" onClick={onIncrease}>
        &#43;
      </span>
    </div>
  );
};
