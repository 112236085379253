import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { volunteerActions } from '@store/slices/volunteer/slice';
import { AdditionalStatusFilters, ApplicantType, HowToHelpType } from '@shared/enums';

export function useFiltersHandlers() {
  const dispatch = useDispatch();

  const onCategoryChange = useCallback((category: HowToHelpType) => {
    dispatch(volunteerActions.setCategoryFilter(category));
  }, []);

  const onCategoryIdsChange = useCallback(async (categoryIds: number[]) => {
    dispatch(volunteerActions.setCategoryIdsFilter(categoryIds));
  }, []);

  const onCategoryItemsIdsChange = useCallback(async (categoryItemsIds: number[]) => {
    dispatch(volunteerActions.setCategoryItemsIdsFilter(categoryItemsIds));
  }, []);

  const onApplicantCategoryIdsChange = useCallback(async (applicantCategoryIds: number[]) => {
    dispatch(volunteerActions.setApplicantCategoryIdsFilter(applicantCategoryIds));
  }, []);

  const onRegionChange = useCallback(async (regionId: number | null) => {
    dispatch(volunteerActions.setRegionIdFilter(regionId));
  }, []);

  const onLocalityChange = useCallback(async (localityId: number | null) => {
    dispatch(volunteerActions.setLocalityIdFilter(localityId));
  }, []);

  const onApplicantTypeChange = useCallback(async (applicantType: ApplicantType | null) => {
    dispatch(volunteerActions.setApplicantTypeFilter(applicantType));
  }, []);

  const onAdditionalFilterChange = useCallback(async (status: AdditionalStatusFilters) => {
    dispatch(volunteerActions.setAdditionalFilter(status));
  }, []);

  const onShopFilterChange = useCallback(async (shop: string | null) => {
    dispatch(volunteerActions.setShopFilter(shop));
  }, []);

  return {
    onCategoryChange,
    onCategoryIdsChange,
    onCategoryItemsIdsChange,
    onApplicantCategoryIdsChange,
    onRegionChange,
    onLocalityChange,
    onApplicantTypeChange,
    onAdditionalFilterChange,
    onShopFilterChange,
  };
}
