import { useWindowDimensions } from '@hooks/useWindowDimensions';
import breakpoints from '@values/breakpoints';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

interface FiltersVisibilityProps {
  defaultVisibility?: boolean;
}

export const useFiltersVisibility: (props: FiltersVisibilityProps) => [boolean, () => void] = (props = {}) => {
  const { defaultVisibility = false } = props;
  const [isFiltersVisible, setFiltersVisibility] = useState(defaultVisibility);
  const isOverXlBreakpoint = useMedia(`(min-width: ${breakpoints.xl}px`);

  const toggleFiltersVisibility = () => setFiltersVisibility((value) => !value);

  useEffect(() => {
    isOverXlBreakpoint ? setFiltersVisibility(true) : setFiltersVisibility(false);
  }, [isOverXlBreakpoint]);

  return [isFiltersVisible, toggleFiltersVisibility];
};
