import React, { useState } from 'react';
import { Customer } from './types';
import { Button } from '@shared/ui/buttons/Button';
import { useTranslation } from 'react-i18next';
import { useToggleState } from '@shared/hooks/useToggleState';
import {
  ContactRequestStatus,
  useCreateContactRequestMutation,
  useGetMySendContactRequestQuery,
} from '@store/api/contact-request.api';
import { useMeQuery } from '@store/api/user.api';
import { EyeIcon } from '@shared/icons/EyeMark';
import { IconNotMark } from '@shared/icons/IconNotMark';
import { ClockIcon } from '@shared/icons/ClockIcon';
import { ApproveContactRequestModal } from '@widgets/customer/profile/approve-contact-request-modal';

const useIsContactRequestSent = (customerId: number) => {
  const { data: contactRequests, isLoading } = useGetMySendContactRequestQuery();

  const contactSend = contactRequests?.items.find((contactRequest) => contactRequest.userInNeed.id === customerId);

  return { isLoading, isContactSend: Boolean(contactSend), status: contactSend?.status };
};
export const ContactRequestApplicationButton = ({
  customer,
  onGiveHelp,
}: {
  customer: Customer;
  onGiveHelp: () => void;
}) => {
  const { data: user } = useMeQuery();
  const [isApproveModalVisible, toggleIsApproveModalVisible] = useToggleState(false);
  const [isHandlerLoading, setIsHandlerLoading] = useState(false);
  const { t } = useTranslation();

  const { isContactSend, isLoading, status } = useIsContactRequestSent(customer.id);

  const [createContactRequest] = useCreateContactRequestMutation();

  const onApprove = async (message: string) => {
    if (!message) return;
    try {
      setIsHandlerLoading(true);
      await createContactRequest({ userInNeedId: customer.id, message }).unwrap();
      toggleIsApproveModalVisible();
      setIsHandlerLoading(false);
    } catch (e: unknown) {
      console.log('error', e);
    }
  };

  if (user?.id === customer.id) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (isContactSend && status === ContactRequestStatus.Approved) {
    return (
      <>
        <Button
          as="link-in"
          variant="rich-blue-2"
          size="small"
          to={`/customer/profile/${customer.id}`}
          className="h-10 items-center text-xxs py-2 px-5 !rounded-3xl flex"
        >
          <EyeIcon />
          <span style={{ marginLeft: 6 }}>{t('see-contact')}</span>
        </Button>
        <Button
          onClick={onGiveHelp}
          as="button"
          variant="rich-blue-2"
          size="small"
          className="h-10 items-center lg:ml-auto mx-auto lg:mr-0 text-xxs py-2 px-5 !rounded-3xl flex mt-2"
        >
          <span style={{ marginLeft: 6 }}>{t('give-help')}</span>
        </Button>
      </>
    );
  }

  if (isContactSend && status === ContactRequestStatus.NotApproved) {
    return (
      <Button
        as="button"
        variant="rich-blue-2"
        size="small"
        disabled
        className="text-xxs py-2 px-5 !rounded-3xl flex items-center"
      >
        <IconNotMark width={19} height={19} />
        <span style={{ marginLeft: 6 }}>{t('cant-see-contact')}</span>
      </Button>
    );
  }

  if (isContactSend) {
    return (
      <Button
        disabled
        as="button"
        variant="rich-blue-2"
        size="small"
        className="text-xxs py-2 px-5 !rounded-3xl flex items-center"
      >
        <ClockIcon />
        <span style={{ marginLeft: 6 }}>{t('contact-requested')}</span>
      </Button>
    );
  }

  return (
    <>
      <Button
        as="button"
        variant="rich-blue"
        size="small"
        className="text-xxs py-2 px-5 !rounded-3xl"
        disabled={false}
        onClick={() => toggleIsApproveModalVisible()}
      >
        {t('contact-request')}
      </Button>
      <ApproveContactRequestModal
        isLoading={isHandlerLoading}
        disabled={false}
        isVisible={isApproveModalVisible}
        onClose={() => toggleIsApproveModalVisible()}
        onApproveHandler={onApprove}
      />
    </>
  );
};
