import React from 'react';

import { InputCloseIcon } from '@shared/icons/InputCloseIcon';

interface ShopChipProps {
  text: string;

  onClick: () => void;
}

export const ShopChip: React.FC<ShopChipProps> = ({ text, onClick }) => {
  return (
    <div className="relative p-[3px] rounded-md bg-bulk-certificates-gradient text-sm cursor-pointer" onClick={onClick}>
      <div className="flex items-center px-[10px] space-x-2 justify-between rounded-md bg-[#f5f6fa] whitespace-nowrap">
        <span className="truncate">{text}</span>

        <InputCloseIcon />
      </div>
    </div>
  );
};
