import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HowToHelpType, VolunteerMode } from '@shared/enums';

import { selectMode } from '@store/slices/volunteer/selectors';
import { volunteerActions } from '@store/slices/volunteer/slice';

export const useVolunteerMode = () => {
  const dispatch = useDispatch();

  const setType = useCallback((mode: VolunteerMode, defaultCategory: HowToHelpType | null = null) => {
    dispatch(volunteerActions.setMode(mode));

    if (mode === 'offline') {
      dispatch(volunteerActions.setCategoryFilter(defaultCategory || HowToHelpType.HandToHand));
    } else {
      dispatch(volunteerActions.setCategoryFilter(defaultCategory || HowToHelpType.OnlineMedicaments));
    }
  }, []);

  return [useSelector(selectMode), setType] as const;
};
