import * as React from 'react';

export const IconFilter = ({ className }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 10H9V8H5V10ZM0 0L0 2H14V0L0 0ZM2.33333 6H11.6667V4H2.33333V6Z" />
    </svg>
  );
};
