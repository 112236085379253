import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { OuterLink } from '@shared/ui/GenericLink';
import { TextareaControlField } from '@shared/ui/TextareaField';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ApproveModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onApproveHandler: (message: string) => void;
  disabled: boolean;
  isLoading: boolean;
};

export const ApproveContactRequestModal = (props: ApproveModalProps) => {
  const { isVisible, onClose, onApproveHandler, disabled, isLoading } = props;
  const iconOptimized = useGetIconOptimized('circle-man-with-laptop');
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [errorText, setErrorText] = useState('');

  const documentLink = `/documents/hand-to-hand-help-manual.pdf`;

  return (
    <ModalBasic
      isVisible={isVisible}
      title="last-step-left"
      secondaryBtnLabel="cancel"
      approveBtnLabel="confirm"
      imageHeading={{ optimized: iconOptimized }}
      onClose={onClose}
      onApprove={() => {
        if (!message) return setErrorText('errors.required');
        onApproveHandler(message);
      }}
      isLoading={isLoading}
      isApproveBtnDisabled={disabled}
    >
      <OuterLink to={documentLink} className="text-shamrock hover:underline text-bold text-xs">
        {t('hand-to-hand-instruction')}
      </OuterLink>
      <br />
      <br />
      <div style={{ fontSize: 15 }} dangerouslySetInnerHTML={{ __html: t('approve-contact-request-description-2') }} />
      <TextareaControlField
        placeholder="message-placeholder"
        label="message-placeholder"
        errorText={errorText}
        onChange={(v: string) => {
          if (v) setErrorText('');
          setMessage(v);
        }}
        value={message}
        className="mt-4"
        id="message"
      />
    </ModalBasic>
  );
};
