import React from 'react';
import { VscClose } from 'react-icons/vsc';

interface ButtonCloseProps {
  onClick: () => void;
  className: string;
  size?: number;
}

export const ButtonClose: React.FC<ButtonCloseProps> = (props) => {
  const { onClick, size = 28, className = '' } = props;

  return (
    <button className={`p-1 ${className}`} onClick={onClick}>
      <VscClose size={size + 'px'} title="Close" />
    </button>
  );
};
