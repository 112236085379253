import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosCheckmark } from 'react-icons/io';

import clsx from 'clsx';

interface ChipChoiceWithCheckmarkProps {
  eventKey: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const ChipChoiceWithCheckmark: React.FC<ChipChoiceWithCheckmarkProps> = (props) => {
  const { eventKey, label, isActive, onClick } = props;
  const { t } = useTranslation();

  const buttonCn = clsx(
    'relative py-3 px-[20px] text-xs border-2 border-solid rounded-2xl transition-colors',
    isActive
      ? 'pl-10 border-black  hover:bg-black-7 active:bg-black-14'
      : 'bg-black-7 border-transparent hover:bg-black-14 active:bg-black/20',
  );

  return (
    <button className={buttonCn} onClick={onClick}>
      {isActive && <IoIosCheckmark className="absolute left-[10px] top-1/2 -translate-y-1/2" size={30} />}
      {t(label)}
    </button>
  );
};
