import { ApplicationView } from '@shared/enums';
import { isDefaultView } from '@shared/utils/applications';
import React from 'react';
import { ApplicationCardItemDetails } from './ApplicationCardItemDetails';
import { ApplicationCardItemsTitle } from './ApplicationCardItemsTitle';
import { ApplicationCardRemovableItem } from './ApplicationCardRemovableItem';
import { ApplicationItem } from './types';
import { useRemovePerformApplicationItem } from '@shared/hooks/useRemovePerformApplicationItem';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  items: ApplicationItem[];
  view: ApplicationView;
};

function usePerformApplicationCardItemsState({ view, items }: Props) {
  const onRemoveHandler = useRemovePerformApplicationItem();
  return { view, items, onRemoveHandler } as const;
}
export const PerformApplicationCardItems = (props: Props) => {
  const { view, items, onRemoveHandler } = usePerformApplicationCardItemsState(props);

  const ApplicationCardItem = isDefaultView(view) ? ApplicationCardRemovableItem : ApplicationCardItemDetails;

  return (
    <div className="lg:col-span-3">
      <ApplicationCardItemsTitle view={view} />
      {items.map((item) => (
        <ApplicationCardItem onRemove={onRemoveHandler} key={item.id} {...item} needConfirm />
      ))}
    </div>
  );
};
