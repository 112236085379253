import React from 'react';

import { ShareIcon } from '@shared/icons/share';
import { Button } from '@shared/ui/buttons/Button';

import { ModalShareLegion } from '@widgets/cabinet/volunteer/modals/ModalShareLegion';

import { useToggle } from '@hooks/useToggle';
import { BadgeVariant } from '@values/legion-badges';

interface ShareButtonProps {
  variant: BadgeVariant;
}

const useComponentState = ({ variant }: ShareButtonProps) => {
  const [isShareModalVisible, toggleIsShareModalVisible] = useToggle({ defaultValue: false });

  return {
    shareModalProps: {
      isVisible: isShareModalVisible,
      onClose: toggleIsShareModalVisible,
      variant,
    },
    actions: {
      showShareModal: () => {
        if (!isShareModalVisible) {
          toggleIsShareModalVisible();
        }
      },
    },
  };
};

export const ShareButton = (props: ShareButtonProps) => {
  const { shareModalProps, actions } = useComponentState(props);

  return (
    <>
      <Button
        as="button"
        variant="secondary"
        onClick={actions.showShareModal}
        icon={ShareIcon}
        iconClassName="mr-3"
        className="flex items-center text-xs mx-auto"
      >
        Поширити
      </Button>

      <ModalShareLegion {...shareModalProps} />
    </>
  );
};
