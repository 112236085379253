import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CertificateAmount } from '@store/api/types';

import { NumberCounter } from '@shared/ui/NumberCounter';

interface CertificatesAmountInputProps {
  amounts: CertificateAmount[];

  onAmountChange: (amount: CertificateAmount) => void;
}

const useComponentState = ({ amounts, onAmountChange }: CertificatesAmountInputProps) => {
  const changeAmountCount = useCallback(
    (value: number, count: number) => {
      if (count < 0) {
        return;
      }

      onAmountChange({ value, count });
    },
    [onAmountChange],
  );

  return {
    amounts,
    actions: {
      changeAmountCount,
    },
  };
};

export const CertificatesAmountInput: React.FC<CertificatesAmountInputProps> = (props) => {
  const { t } = useTranslation();
  const { amounts, actions } = useComponentState(props);

  return (
    <div className="flex flex-col space-y-6">
      {amounts.map(({ value, count }) => (
        <div key={value} className="flex justify-between">
          <span className="text-base text-black font-normal">
            {value} {t('uah')}
          </span>

          <NumberCounter
            value={count}
            onIncrease={() => actions.changeAmountCount(value, count + 1)}
            onDecrease={() => actions.changeAmountCount(value, count - 1)}
          />
        </div>
      ))}
    </div>
  );
};
