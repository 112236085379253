import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Element } from '@shared/interfaces/element.interface';

interface TextareaFieldProps extends Element {
  id: string;
  name?: string;
  label: string;
  placeholder?: string;
  helperText?: string;
}

export const TextareaField = (props: TextareaFieldProps) => {
  const { id, name = id, label, placeholder = '', className = '', helperText = '' } = props;
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorText = errors[name]?.message;

  return (
    <div className={clsx('relative min-w-62', className)}>
      <textarea
        {...register(name)}
        id={id}
        placeholder={t(placeholder)}
        className={clsx(
          'peer w-full h-64 placeholder-shown:h-28 pt-5 pb-3 px-4 outline-none bg-transparent transition-all text-black border-b-[1px] border-b-solid rounded-none placeholder:text-black-50 hover:bg-black-7 focus:bg-black-7 resize-y placeholder-shown:py-3',
          errorText ? 'border-b-carnation' : 'border-b-black',
        )}
      />
      <label
        htmlFor={id}
        className="absolute left-0 top-[1px] px-4 pointer-events-none w-full transition-all text-xs text-black-50 peer-placeholder-shown:text-transparent peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-base"
      >
        {t(label)}
      </label>
      {errorText && <span className="px-4 py-[5px] text-xxs text-carnation">{t(errorText)}</span>}
      {!errorText && helperText && <span className="px-4 py-[5px] text-xxs text-black-50">{helperText}</span>}
    </div>
  );
};

export const TextareaControlField = (
  props: TextareaFieldProps & { value: string; errorText: string; onChange: (v: string) => void },
) => {
  const { id, label, placeholder = '', className = '', helperText = '', value, errorText, onChange } = props;
  const { t } = useTranslation();

  return (
    <div className={clsx('relative min-w-62', className)}>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        id={id}
        placeholder={t(placeholder)}
        className={clsx(
          'peer w-full h-64 placeholder-shown:h-28 pt-5 pb-3 px-4 outline-none bg-transparent transition-all text-black border-b-[1px] border-b-solid rounded-none placeholder:text-black-50 hover:bg-black-7 focus:bg-black-7 resize-y placeholder-shown:py-3',
          errorText ? 'border-b-carnation' : 'border-b-black',
        )}
      />
      <label
        htmlFor={id}
        className="absolute left-0 top-[1px] px-4 pointer-events-none w-full transition-all text-xs text-black-50 peer-placeholder-shown:text-transparent peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-base"
      >
        {t(label)}
      </label>
      {errorText && <span className="px-4 py-[5px] text-xxs text-carnation">{t(errorText)}</span>}
      {!errorText && helperText && <span className="px-4 py-[5px] text-xxs text-black-50">{helperText}</span>}
    </div>
  );
};
