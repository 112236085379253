import React, { useCallback, useEffect, useMemo } from 'react';

import { useUser } from '@shared/hooks/useUser';

import { cabinetTabsData } from '../constants';
import { useActiveTab } from '../modals/hooks/useActiveTab';
import { CabinetTabs } from '../../cabinetTabs/CabinetTabs';
import { useVolunteerCabinetApplicationStats } from '../modals/hooks/useVolunteerCabinetApplicationStats';
import { useFilters } from '../modals/hooks/useFilters';
import { Element } from '@shared/interfaces/element.interface';

interface CabinetVolunteerTabsProps extends Element {}

export const CabinetVolunteerTabs: React.FC<CabinetVolunteerTabsProps> = ({ className }) => {
  const [user] = useUser();
  const [activeTab, setActiveTab] = useActiveTab();

  /**
   * HACK: we need this code temporary to show users santa-claus-letter tab
   *
   * TODO: remove this
   */
  const filters = useFilters();

  const handleTabChange = useCallback((key: string) => setActiveTab(key), [setActiveTab]);

  const applicationStats = useVolunteerCabinetApplicationStats();

  const tabList = useMemo(
    () =>
      cabinetTabsData
        /**
         * HACK: we need this code temporary to show users santa-claus-letter tab
         *
         * TODO: remove this
         */
        .filter((tabData) => tabData.isAllowed(user, filters))
        .map((item) => ({
          ...item,
          isActive: item.eventKey === activeTab,
          indication: applicationStats[item.eventKey].value,
          isLoading: applicationStats[item.eventKey].isLoading,
          onClick: () => handleTabChange(item.eventKey),
        })),
    [activeTab, filters, applicationStats, handleTabChange],
  );

  useEffect(() => {
    const isActiveTabExists = tabList.find((tab) => tab.eventKey === activeTab);

    if (!isActiveTabExists && tabList.length > 0) {
      setActiveTab(tabList.at(-1).eventKey);
    }
  }, [tabList, activeTab]);

  return <CabinetTabs tabList={tabList} className={className} />;
};
