import React, { useMemo } from 'react';

import { useToggle } from '@hooks/useToggle';

import { AccordionBasic } from '@shared/accordion/AccordionBasic';
import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';

import { ALL, CABINET_TAB_NEW } from '../constants';
import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';
import { useIsVisibleGuard } from '../modals/hooks/useIsVisibleGuard';
import { HowToHelpType } from '@shared/enums';
import { useTranslation } from 'react-i18next';

export const HandToHandNeedsFilters = ({ items }) => {
  const { categoryItemIds: selected } = useFilters();
  const { onCategoryItemsIdsChange } = useFiltersHandlers();
  const { t } = useTranslation();

  const [isAccordionOpen, handleToggleAccordion] = useToggle({ defaultValue: true });

  const categories = useMemo(() => items.map(({ id, name }: any) => ({ id, name })), [items]);

  const handleAllCategoriesToggle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onCategoryItemsIdsChange(evt.target.checked ? categories.map((item: any) => item.id) : []);
  };

  const handleCategoryToggle = (evt: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (evt.target.checked) {
      onCategoryItemsIdsChange([...selected, id]);
    } else {
      onCategoryItemsIdsChange(selected.filter((itemId: number) => itemId !== id));
    }
  };

  const isVisible = useIsVisibleGuard({
    tab: [CABINET_TAB_NEW],
    category: [HowToHelpType.HandToHand],
  });
  if (!isVisible) return null;

  return (
    <AccordionBasic label="needs" isOpen={isAccordionOpen} onClick={handleToggleAccordion} className="mt-6">
      <CheckboxNotFormField
        name="all-needs"
        isChecked={selected.length === categories.length}
        label={t(ALL)}
        className="mt-5 first:mt-0"
        onChange={handleAllCategoriesToggle}
      />
      {categories.map((item: any) => (
        <CheckboxNotFormField
          key={item.name}
          name={item.name}
          label={t(item.name)}
          isChecked={selected.includes(item.id)}
          onChange={(evt) => handleCategoryToggle(evt, item.id)}
          className="mt-5 first:mt-0"
        />
      ))}
    </AccordionBasic>
  );
};
