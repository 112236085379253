import React from 'react';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';
import { ModalBodyFailedPayment } from './bodies/ModalBodyFailedPayment';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

type ModalFailedPaymentProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove'>;

export const ModalFailedPayment: React.FC<ModalFailedPaymentProps> = ({ isVisible, onClose, onApprove }) => {
  const iconOptimized = useGetIconOptimized('circle-wow-face');
  const imageHeading = {
    optimized: iconOptimized,
  };

  return (
    <ModalBasic
      hasHeaderCloseBtn={false}
      isVisible={isVisible}
      title="sorry-for-error"
      imageHeading={imageHeading}
      secondaryBtnLabel="cancel"
      approveBtnLabel="retry"
      onClose={onClose}
      onApprove={onApprove}
    >
      <ModalBodyFailedPayment />
    </ModalBasic>
  );
};
