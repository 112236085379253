import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useShops } from '@hooks/useShops';

import { ShopChip } from '@widgets/cabinet/volunteer/content/ShopChip';
import { Element } from '@shared/interfaces/element.interface';

import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';

interface BulkPurchasesFiltersProps extends Element {}

const useComponentState = ({ className }: BulkPurchasesFiltersProps) => {
  const shops = useShops();
  const filters = useFilters();

  const { onShopFilterChange } = useFiltersHandlers();

  const shop = useMemo(() => {
    return shops.find(({ name }) => name === filters.shop) || null;
  }, [shops, filters.shop]);

  const onShopClick = () => onShopFilterChange(null);

  return { className, shop, onShopClick };
};

export const BulkPurchasesFilters: React.FC<BulkPurchasesFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { className, shop, onShopClick } = useComponentState(props);

  if (!shop) {
    return null;
  }

  return (
    <div className={clsx(className)}>
      <ShopChip text={t(shop.label)} onClick={onShopClick} />
    </div>
  );
};
