import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from '@shared/hooks/useSearchParams';
import { updateSearchParams } from '@shared/utils/path';

export enum ModalVisibleEvent {
  HelpOnlineSuccess = 'helpOnlineSuccess',

  HelpOnlineError = 'helpOnlineError',

  BulkPurchaseSuccess = 'bulkPurchaseSuccess',

  BulkPurchaseFailure = 'bulkPurchaseFailure',
}

export function useModalVisible(event: ModalVisibleEvent) {
  const searchParams = useSearchParams();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!searchParams) return;

    setIsVisible(searchParams.get('event') === event);
  }, [searchParams]);

  const hideModal = useCallback(() => {
    updateSearchParams((params) => {
      params.delete('event');

      params.delete('bonus');

      params.delete('shop');

      return params;
    });
  }, []);

  return [isVisible, hideModal] as const;
}
