import { useIsCollapsed } from '@hooks/useIsCollapsed';
import React from 'react';
import { UserRating } from '../rating/user-rating';
import { ActiveOnlineApplicationCardItems } from './ActiveOnlineApplicationCardItems';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { ApplicationCardUserReceivedHelp } from './ApplicationCardUserReceivedHelp';
import { ApplicationCardWhoNeeds } from './ApplicationCardWhoNeeds';
import { PerformOnlineApplicationCardAmount } from './PerformOnlineApplicationCardAmount';
import { ShowReceiptButton } from './ShowReceiptButton';
import { ApplicationCardProps } from './types';

function useCompleteOnlineCardState(props: ApplicationCardProps) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: props.id,
    customer: props.customer,
    purchase: props.purchase,
    view: props.view,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      items: props.items,
      view: props.view,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'completed-secondary',
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
  } as const;
}

export const CompleteOnlineApplicationCard = (props: ApplicationCardProps) => {
  const {
    purchase,
    view,
    customer,
    applicationCardAddressProps,
    applicationCardWhoNeedsProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    collapsedProps,
  } = useCompleteOnlineCardState(props);

  return (
    <div className="p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <div className="flex flex-col items-start justify-between w-100 lg:grid lg:grid-cols-5 lg:items-start lg:mb-4 lg:gap-5">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4 lg:mb-0 col-span-2" />

        <UserRating value={customer.rating} className="mb-4 order-first lg:mb-0 lg:order-none" />

        <ApplicationCardCollapseController
          className="hidden lg:flex col-end-[-1] col-span-2 justify-self-end"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col items-start justify-between mb-4 w-100 lg:grid lg:grid-cols-5 lg:items-start gap-5">
        <ApplicationCardWhoNeeds
          className="col-span-2"
          {...applicationCardWhoNeedsProps}
          withoutClamp={!collapsedProps.isCollapsed}
        />
        <PerformOnlineApplicationCardAmount className="col-start-3 col-end-3" {...applicationCardAmountProps} />
        <ApplicationCardUserReceivedHelp customer={customer} />
        <div className="self-center lg:self-start lg:justify-self-end whitespace-nowrap">
          <ShowReceiptButton purchase={purchase} />
        </div>
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>

      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <ActiveOnlineApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
