import { useMemo } from 'react';
import { useGetApplicationsStatsQuery, useGetTotalActiveApplicationsQuery } from '@store/api/applications.api';
import { ApplicationState, ApplicationTarget } from '@shared/enums';
import { useVolunteerApplicationsParams } from './useVolunteerApplicationsParams';

const defaultStats = {
  [ApplicationState.New]: 0,
  [ApplicationState.Processed]: 0,
  [ApplicationState.InProgress]: 0,
  [ApplicationState.Completed]: 0,
};

export function useVolunteerCabinetApplicationStats() {
  const params = useVolunteerApplicationsParams();

  const { page, state, ...other } = params;

  const applicationStats = useGetApplicationsStatsQuery({
    target: ApplicationTarget.Volunteer,
    category: other.category,
  });
  const activeApplicationStats = useGetTotalActiveApplicationsQuery(other);

  const activeApplicationStatsIsLoading = activeApplicationStats?.isLoading || activeApplicationStats?.isFetching;
  const applicationStatsIsLoading = applicationStats?.isLoading || applicationStats?.isFetching;

  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries(applicationStats?.data?.byState || defaultStats).map(([state, value]) => [
          state,
          {
            value: (state === ApplicationState.New ? activeApplicationStats?.data?.totalItems : value) || 0,
            isLoading: state === ApplicationState.New ? activeApplicationStatsIsLoading : applicationStatsIsLoading,
          },
        ]),
      ),
    [applicationStats, activeApplicationStats],
  );
}
