import React, { useCallback, useMemo } from 'react';

import { CabinetWhoNeedHelpFilters } from '@widgets/cabinet/cabinetFilters/CabinetWhoNeedHelpFilters';

import { volunteerWhoNeedHelpFilters } from '@values/cabinet';

import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';

import { CABINET_TAB_NEW, CABINET_TAB_PERFORM } from '../constants';
import { useIsVisibleGuard } from '../modals/hooks/useIsVisibleGuard';
import { HowToHelpType } from '@shared/enums';

export const VolunteerWhoNeedHelpFilters = () => {
  const { applicantType } = useFilters();
  const { onApplicantTypeChange } = useFiltersHandlers();

  const whoNeedHelpList = useMemo(
    () => volunteerWhoNeedHelpFilters.map((item) => ({ ...item, isActive: item.eventKey === (applicantType || '') })),
    [applicantType],
  );
  const handleChange = useCallback((eventKey) => onApplicantTypeChange(eventKey), [onApplicantTypeChange]);

  const isVisible = useIsVisibleGuard({
    category: [HowToHelpType.HandToHand],
    tab: [CABINET_TAB_NEW, CABINET_TAB_PERFORM],
  });
  if (!isVisible) return null;

  return <CabinetWhoNeedHelpFilters whoNeedHelpList={whoNeedHelpList} handleChange={handleChange} />;
};
