import { ApplicantType, HowToHelpType } from '@shared/enums';
import { VolunteerMode } from '@store/slices/volunteer/slice';

import { useActiveTab } from './useActiveTab';
import { useFilters } from './useFilters';
import { useVolunteerMode } from './useVolunteerMode';

type WhoNeedHelp = ApplicantType | '' | null;

interface UseIsVisibleGuardOptions {
  mode?: VolunteerMode[];
  category?: HowToHelpType[];
  tab?: string[];
  whoNeedHelp?: WhoNeedHelp[];
}

export function useIsVisibleGuard(options: UseIsVisibleGuardOptions) {
  const filters = useFilters();
  const [activeTab] = useActiveTab();
  const [currentMode] = useVolunteerMode();

  const { mode = [], category = [], tab = [], whoNeedHelp = [] } = options;

  if (category.length > 0 && !category.includes(filters.category)) {
    return false;
  }

  if (tab.length > 0 && !tab.includes(activeTab)) {
    return false;
  }

  if (whoNeedHelp.length > 0 && !whoNeedHelp.includes(filters.applicantType)) {
    return false;
  }

  if (mode.length > 0 && !mode.includes(currentMode)) {
    return false;
  }

  return true;
}
