import { ApplicantType, ApplicationState } from '@shared/enums';
import { useCancelApplicationItemsMutation, useGetApplicationsQuery } from '@store/api/applications.api';
import { useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/slices/auth';
import { selectPagination } from '@store/slices/volunteer/selectors';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';
import { CABINET_TAB_PERFORM } from '@widgets/cabinet/volunteer/constants';
import { useFilters } from '@widgets/cabinet/volunteer/modals/hooks/useFilters';

export function useRemovePerformApplicationItems(applicationId: number) {
  const user = useAppSelector(selectAuthUser);
  const pagination = useAppSelector(selectPagination);
  const { category, regionId, localityId, categoryIds, categoryItemIds } = useFilters();

  const queryParams = {
    target: ApplicationsTotalTarget.Volunteer,
    state: CABINET_TAB_PERFORM,
    category,
    type: ApplicantType.HumanBeing,
    regionId,
    categoryIds,
    categoryItemIds,
    localityId,
    page: pagination.page,
    perPage: pagination.perPage,
  };
  const { application }: { application?: Application } = useGetApplicationsQuery(queryParams, {
    selectFromResult: ({ data }) => ({
      application: data?.items.find((item: Application) => item.id === applicationId),
    }),
  });

  const [cancelApplicationItems] = useCancelApplicationItemsMutation();

  const onRemoveHandler = () => {
    if (user && application) {
      const itemIds = application.items.map((item) => item.id);
      cancelApplicationItems({
        target: ApplicationsTotalTarget.Volunteer,
        itemIds,
        state: ApplicationState.InProgress,
      });
    }
  };

  return onRemoveHandler;
}
