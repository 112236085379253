import { useEffect, useState } from 'react';

import { updateSearchParams } from '@shared/utils/path';
import { useSearchParams } from './useSearchParams';

interface UseStateQueryParamOptions<T> {
  defaultValue: string | null;

  serialize?: (value: T) => string | null;

  validate?: (value: T | string | null) => boolean;

  onStateUpdate: (value: string | null) => void;
}

/**
 * Sync query params with some value (e.g redux state)
 *
 * TODO: refactor, check with others params, test serialize function
 *
 *  @param param {string} Name of query param (e.g 'page')
 *  @param state {T} Value from redux state or other watched value
 */
export function useSyncStateQueryParam<T extends unknown>(
  param: string,
  state: T,
  { defaultValue, validate, serialize = (value) => String(value), onStateUpdate }: UseStateQueryParamOptions<T>,
) {
  const params = useSearchParams();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!params) return;

    if (!initialized) setInitialized(true);

    let value: string | null = params.get(param);
    if (!value || value === 'null' || value === 'undefined') {
      value = defaultValue;
    }

    if (validate && !validate(value)) {
      value = defaultValue;
    }

    if (serialize(state) === value) return;

    onStateUpdate(value);
  }, [params?.get(param) || null]);

  useEffect(() => {
    if (!params || !initialized) return;

    const serialized = serialize(state);
    if (serialized === params.get(param)) {
      return;
    }

    updateSearchParams((searchParams) => {
      if (serialized) {
        searchParams.set(param, serialized);
      } else {
        searchParams.delete(param);
      }

      return searchParams;
    });
  }, [state]);
}
