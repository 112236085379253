import { useRemoveVerifyingApplicationItems } from '@shared/hooks/useRemoveVerifyingApplicationItems';
import clsx from 'clsx';
import React, { useState } from 'react';
import { UserRating } from '../rating/user-rating';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { ApplicationCardWhoNeeds } from './ApplicationCardWhoNeeds';
import { DenyApplicationButton } from './DenyApplicationButton';
import { ForwardToPaymentButton } from './ForwardToPaymentButton';
import { Application, ApplicationCardProps, ApplicationItem, Customer } from './types';
import { VerificationApplicationCardItems } from './VerificationApplicationCardItems';
import { ContactRequestApplicationButton } from './ContactRequestApplicationButton';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ApplicationView } from '@shared/enums';
import { ApplicationConfirmModalBody } from '../volunteer/modals/bodies/ApplicationConfirmModalBody';
import { useExecuteApplicationItemsMutation } from '@store/api/applications.api';

const GiveHelpModal = ({
  show,
  onClose,
  onConfirm,
  onRemove,
  applicationCardItemsProps,
}: {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onRemove: (id: number) => void;
  applicationCardItemsProps: {
    customer: Customer;
    items: ApplicationItem[];
    view: ApplicationView;
  };
}) => {
  const iconOptimized = useGetIconOptimized('circle-man-with-thumbs-up');
  const imageHeading = {
    optimized: iconOptimized,
  };

  return (
    <ModalBasic
      isVisible={show}
      imageHeading={imageHeading}
      title="please-confirm"
      secondaryBtnLabel="cancel-2"
      approveBtnLabel="confirm"
      onClose={onClose}
      onApprove={onConfirm}
      contentClassName="overflow-y-auto max-h-70vh md:max-h-60vh"
    >
      <ApplicationConfirmModalBody
        application={applicationCardItemsProps as Application}
        onRemove={onRemove}
        className="mt-2"
      />
    </ModalBasic>
  );
};

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function useVerificationCardState(props: ApplicationCardProps) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();
  const onDenyButtonClickHandler = useRemoveVerifyingApplicationItems(props.id);
  const onForwardToPaymentClick = () => {
    if (!props.purchase?.redirectUrl) return;

    // TODO: refactor to redirect fn
    window.location.href = props.purchase.redirectUrl;
  };

  const [showGiveHelpModal, setShowGiveHelpModal] = useState(false);
  const [appItems, setAppItems] = useState<ApplicationItem[]>(props.items);

  const [executeAppItems] = useExecuteApplicationItemsMutation();

  const onConfirm = async () => {
    setShowGiveHelpModal(true);
    try {
      await executeAppItems({ items: appItems.map((ai) => ({ id: ai.id })) }).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setShowGiveHelpModal(true);
    }
  };

  return {
    applicationId: props.id,
    purchase: props.purchase,
    customer: props.customer,
    view: props.view,
    showForwardToPaymentButton: !!props.purchase?.redirectUrl,
    onGiveHelp: () => setShowGiveHelpModal(true),
    giveHelpModalProps: {
      show: showGiveHelpModal,
      onClose: () => {
        setShowGiveHelpModal(false);
        setAppItems(props.items);
      },
      onConfirm,
      onRemove: (id: number) => {
        setAppItems((items) => {
          const newAppItems = items.filter((ai) => ai.id !== id);
          if (newAppItems.length === 0) {
            setShowGiveHelpModal(false);
            return props.items;
          }

          return newAppItems;
        });
      },
      applicationCardItemsProps: {
        customer: props.customer,
        items: appItems,
        view: props.view,
      },
    },
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      items: props.items,
      view: props.view,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'processed',
    },
    applicationCardItemsProps: {
      customer: props.customer,
      items: props.items,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    denyButtonProps: {
      onClick: onDenyButtonClickHandler,
      needConfirm: true,
    },
    forwardToPaymentButtonProps: {
      onClick: onForwardToPaymentClick,
    },
  } as const;
}

export const VerificationApplicationCard = (props: ApplicationCardProps) => {
  const {
    customer,
    view,
    onGiveHelp,
    giveHelpModalProps,
    applicationCardAddressProps,
    applicationCardWhoNeedsProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    forwardToPaymentButtonProps,
    collapsedProps,
    denyButtonProps,
    showForwardToPaymentButton,
  } = useVerificationCardState(props);

  return (
    <div className="p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <GiveHelpModal {...giveHelpModalProps} />
      <div className="flex flex-col items-start justify-between w-100 lg:grid lg:grid-cols-6 lg:items-start lg:mb-4 gap-0">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4 lg:mb-0 col-span-2" />

        <UserRating value={customer.rating} className="mb-4 order-first lg:mb-0 lg:order-none" />

        <ApplicationCardCollapseController
          className="hidden lg:flex col-end-[-1] col-span-2 justify-self-end"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col justify-between mb-4 w-100 lg:grid lg:grid-cols-6 lg:items-start gap-5">
        <ApplicationCardWhoNeeds
          className="col-span-2"
          {...applicationCardWhoNeedsProps}
          withoutClamp={!collapsedProps.isCollapsed}
        />
        <ApplicationCardAmount
          className={clsx(
            !showForwardToPaymentButton && 'col-start-3 col-end-4',
            showForwardToPaymentButton && 'col-start-3 col-end-4',
          )}
          {...applicationCardAmountProps}
        />
        <div
          className={clsx(
            'lg:ml-auto self-center flex-col space-y-5 items-center sm:space-y-0 sm:flex-row sm:space-x-5',
            showForwardToPaymentButton && 'col-start-4 col-end-7',
            !showForwardToPaymentButton && 'col-start-4 col-end-7',
          )}
        >
          <div className="mb-2">
            {showForwardToPaymentButton && <ForwardToPaymentButton {...forwardToPaymentButtonProps} />}
            {!showForwardToPaymentButton && (
              <ContactRequestApplicationButton customer={customer} onGiveHelp={onGiveHelp} />
            )}
          </div>
          <div className="mb-2 flex-col">
            <DenyApplicationButton {...denyButtonProps} className="lg:ml-auto mx-auto lg:mr-0" />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <VerificationApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
