import { useMemo } from 'react';
import { useFilters } from '../modals/hooks/useFilters';
import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';
import { useUser } from '@shared/hooks/useUser';

export const useChipLists = () => {
  const { category } = useFilters();

  const integrationConfigs = useIntegrationConfigs();
  const options = useMemo(
    () =>
      integrationConfigs.map((integration) => ({
        isActive: false,
        isOfflineSupported: integration.isOfflineSupported,
        isAllowedForGuests: integration.isAllowedForGuests,
        eventKey: integration.type,
        label: integration.name,
        emoji: integration.icon ? undefined : integration.emoji,
        icon: integration.icon,
      })),
    [integrationConfigs],
  );

  const [user] = useUser();
  const chipList = useMemo(
    () =>
      options
        .filter((item) => (user?.isMember ? true : item.isAllowedForGuests))
        .map((item) => ({
          ...item,
          isActive: item.eventKey === category,
        })),
    [category, options, user?.isMember],
  );

  const offlineChipList = useMemo(
    () =>
      options
        .filter((item) => item.isOfflineSupported)
        .map((item) => ({
          ...item,
          isActive: item.eventKey === category,
        })),
    [category, options],
  );

  return { chipList, offlineChipList };
};
