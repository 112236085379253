import { ChipChoiceWithCheckmark } from '@shared/ui/chips/ChipChoiceWithCheckmark';
import React from 'react';

export const CabinetWhoNeedHelpFilters = (props) => {
  const { whoNeedHelpList, handleChange } = props;

  return (
    <div className="flex flex-wrap mt-6 gap-x-2 gap-y-2 xl:gap-x-3">
      {whoNeedHelpList.map((item: any) => (
        <ChipChoiceWithCheckmark key={item.eventKey} {...item} onClick={() => handleChange(item.eventKey)} />
      ))}
    </div>
  );
};
