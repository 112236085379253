import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaCreditCard } from 'react-icons/fa';

import { Button } from '@shared/ui/buttons/Button';

type ForwardToPaymentButtonProps = {
  onClick: () => void;
  className?: string;
};

export const ForwardToPaymentButton = ({ onClick, className }: ForwardToPaymentButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      size="tiny"
      variant="secondary"
      onClick={onClick}
      icon={FaCreditCard}
      iconClassName="mr-3"
      className={clsx('flex items-center whitespace-nowrap', className)}
    >
      {t('forward-to-payment')}
    </Button>
  );
};
