import { FacebookIcon, } from "@shared/icons/FacebookIcon";
import { LinkedinIcon } from '@shared/icons/LinkedinIcon';
import { TwitterIcon } from '@shared/icons/TwitterIcon';
import { IconProps } from "@shared/interfaces/icon.interface";

import { FC, useMemo } from "react";

const useSocialShareLinks = (link: string): {links: string[], buttons: {link: string, icon: FC<IconProps>}[]} => {
    const fb = useMemo(() => `https://www.facebook.com/sharer/sharer.php?u=${link}`, [link]);
    const twitter = useMemo(() => `https://twitter.com/intent/tweet?url=${link}`, [link]);
    const linkedin = useMemo(() => `https://www.linkedin.com/shareArticle?mini=true&url=${link}`, [link]);
    const links = [fb, twitter, linkedin];
    const buttons = [
        {link: fb, icon: FacebookIcon},
        {link: twitter, icon: TwitterIcon},
        {link: linkedin, icon: LinkedinIcon},
    ]
    return {links, buttons }
}

export default useSocialShareLinks;