import React from 'react';
import clsx from 'clsx';

interface ProgressBarBreakpointProps {
  min: number;

  max: number;

  value: number;

  isMinHidden?: boolean;
}

const useComponentState = ({ min, max, value, isMinHidden }: ProgressBarBreakpointProps) => {
  const isMinPassed = value >= min;
  const isMaxPassed = value >= max;

  const progress = Math.min(100, Math.max(((value - min) * 100) / (max - min), 0));

  return {
    min,
    max,
    progress: `${progress}%`,
    minBg: isMinPassed ? 'bg-shamrock' : 'bg-black-14',
    maxBg: isMaxPassed ? 'bg-shamrock' : 'bg-black-14',
    isMinHidden,
  };
};

export const ProgressBarBreakpoint: React.FC<ProgressBarBreakpointProps> = (props) => {
  const { min, max, progress, minBg, maxBg, isMinHidden } = useComponentState(props);

  return (
    <div className="flex grow items-center space-x-[1px]">
      {!isMinHidden && (
        <div className={clsx('relative w-[2px] h-[10px] mb-5 rounded-xxxs', minBg)}>
          <span className="absolute top-full left-1/2 -translate-x-2/4 text-xxs font-light text-black-50">{min}</span>
        </div>
      )}

      <div className="grow h-[6px] mb-5 overflow-hidden rounded-xxxs bg-black-14">
        <div className="h-full bg-black bg-shamrock rounded-tr-xxxs rounded-br-xxxs" style={{ width: progress }}>
          <span className="visually-hidden">{progress}</span>
        </div>
      </div>

      <div className={clsx('relative w-[2px] h-[10px] mb-5 rounded-xxxs', maxBg)}>
        <span className="absolute top-full left-1/2 -translate-x-2/4 text-xxs font-light text-black-50">{max}</span>
      </div>
    </div>
  );
};
