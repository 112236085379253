import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';

type ModalGratitudeBoughtCertificatesProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove'>;

export const ModalGratitudeBoughtCertificates: React.FC<ModalGratitudeBoughtCertificatesProps> = ({
  isVisible,
  onClose,
  onApprove,
}) => {
  const icon = useGetIconOptimized('circle-man-with-thumbs-up-gradient');
  const { t } = useTranslation();

  return (
    <ModalBasic
      isVisible={isVisible}
      title="grat-buy-certificates-title"
      imageHeading={{ optimized: icon }}
      approveBtnLabel="ok"
      onClose={onClose}
      onApprove={onApprove}
    >
      <p className="text-xs md:text-base">{t('grat-buy-certificates-desc')}</p>
    </ModalBasic>
  );
};
