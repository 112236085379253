import { useRemovePerformApplicationItems } from '@shared/hooks/useRemovePerformApplicationItems';
import clsx from 'clsx';
import React, { useState } from 'react';
import { UserRating } from '../rating/user-rating';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { ApplicationCardUserReceivedHelp } from './ApplicationCardUserReceivedHelp';
import { ApplicationCardWhoNeeds } from './ApplicationCardWhoNeeds';
import { DenyApplicationButton } from './DenyApplicationButton';
import { PerformApplicationCardAmount } from './PerformApplicationCardAmount';
import { PerformApplicationCardItems } from './PerformApplicationCardItems';
import { ApplicationCardProps } from './types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import { format } from 'date-fns';
import { ApplicationState } from '@shared/enums';
import { VolunteerHandToHandChangeDate } from './VolunteerHandToHandChangeDate';

const DateView = ({ date }: { date: string }) => {
  const { t } = useTranslation();
  return (
    <div>
      <span className="text-black-50 text-xxs mr-2">{t('close-until')}</span>
      <span>{format(new Date(date), 'dd.MM.yyyy')}</span>
    </div>
  );
};

const VolunteerHandToHandTip = () => {
  const { t } = useTranslation();
  const params = useSearchParams();

  const category = params?.get('category');

  if (category !== 'hand') {
    return null;
  }

  return <div className="text-black-50 text-xxs mt-4 text-center">{t('user-in-need-must-confirm')}</div>;
};

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function usePerformCardState(props: ApplicationCardProps) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();
  const onDenyButtonClickHandler = useRemovePerformApplicationItems(props.id);

  const canChangeInProgressTo =
    props.items.every((item) => item.state === ApplicationState.Processed) &&
    !!props.inProgressAt &&
    !!props.inProgressTo;

  return {
    applicationId: props.id,
    customer: props.customer,
    purchase: props.purchase,
    view: props.view,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      items: props.items,
      view: props.view,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'doing-needs',
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    denyButtonProps: {
      onClick: onDenyButtonClickHandler,
      needConfirm: true,
    },
    canChangeInProgressTo,
    changeDateProps: {
      inProgressAt: props.inProgressAt,
      inProgressTo: props.inProgressTo,
      items: props.items,
    },
  } as const;
}

export const PerformApplicationCard = (props: ApplicationCardProps) => {
  const {
    customer,
    view,
    denyButtonProps,
    applicationCardAddressProps,
    applicationCardWhoNeedsProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    collapsedProps,
    canChangeInProgressTo,
    changeDateProps,
  } = usePerformCardState(props);

  return (
    <div className="p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <div className="flex flex-col items-start justify-between w-100 lg:grid lg:grid-cols-5 lg:items-start lg:mb-4 lg:gap-5">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4 lg:mb-0 col-span-2" />

        <UserRating value={customer.rating} className="mb-4 order-first lg:mb-0 lg:order-none" />

        <ApplicationCardCollapseController
          className="hidden lg:flex col-end-[-1] col-span-2 justify-self-end"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col items-start justify-between mb-4 w-100 lg:grid lg:grid-cols-5 lg:items-start gap-5">
        <ApplicationCardWhoNeeds
          className="col-span-2"
          {...applicationCardWhoNeedsProps}
          withoutClamp={!collapsedProps.isCollapsed}
        />
        <PerformApplicationCardAmount className="col-start-3 col-end-3" {...applicationCardAmountProps} />
        <ApplicationCardUserReceivedHelp customer={customer} />

        <div className="self-center justify-self-end col-end-[-1]">
          <DenyApplicationButton {...denyButtonProps} />
        </div>
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <PerformApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
      {canChangeInProgressTo && <VolunteerHandToHandChangeDate {...changeDateProps} />}
      <VolunteerHandToHandTip />
    </div>
  );
};
