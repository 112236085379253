import { useMemo } from 'react';

import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';

import { useFilters } from './useFilters';
import { useActiveTab } from './useActiveTab';
import { useVolunteerPagination } from './useVolunteerPagination';

export function useVolunteerApplicationsParams() {
  const filters = useFilters();

  const [activeTab] = useActiveTab();
  const pagination = useVolunteerPagination();

  return useMemo(
    () => ({
      state: activeTab,
      target: ApplicationsTotalTarget.Volunteer,
      category: filters.category,
      applicantType: filters.applicantType,
      regionId: filters.regionId,
      localityId: filters.localityId,
      categoryIds: filters.categoryIds,
      categoryItemIds: filters.categoryItemIds,
      groups: filters.applicantCategoryIds,
      additional: filters.additional,
      shopName: filters.shop,
      page: pagination.page,
      perPage: pagination.perPage,
      shopName: filters.shop,
    }),
    [filters, activeTab, pagination],
  );
}
