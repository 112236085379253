import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalBasic } from '@shared/ui/modal/ModalBasic';

import { Shop, useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

import { CertificatesAmountInput } from '../form/certificates-amount-input';
import { ShopOfferAgreementCheckbox } from '../form/shop-offer-agreement-checkbox';

import { CertificateAmount } from '@store/api/types';
import { useBuyCertificates } from './hooks/useBuyCertificates';

interface ModalBuyCertificatesProps {
  isVisible: boolean;

  onClose: () => void;
}

const useShopActions = () => {
  const { t } = useTranslation();
  const integrationConfigs = useIntegrationConfigs();

  const shopOptions = useMemo(() => {
    const shops = integrationConfigs.flatMap((integrationConfig) =>
      integrationConfig.shops.filter(({ config }) => config?.isBulkPurchasesSupported),
    );

    return shops.map((shop) => ({ label: t(shop.label), value: shop }));
  }, [integrationConfigs]);

  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [amounts, setAmounts] = useState<CertificateAmount[]>([]);

  const changeAmount = (changedAmount: CertificateAmount) => {
    setAmounts((amounts) => amounts.map((amount) => (amount.value !== changedAmount.value ? amount : changedAmount)));
  };

  const selectShop = (shop: Shop | null) => {
    setSelectedShop(shop || null);

    if (shop) {
      setAmounts(shop.config.allowedAmounts.map((value) => ({ value, count: 0 })));
    } else {
      setAmounts([]);
    }
  };

  return {
    amounts,

    selectedShop,
    shopOptions,

    actions: {
      selectShop,
      changeAmount,
    },
  };
};

const useComponentState = () => {
  const { shopOptions, selectedShop, amounts, actions } = useShopActions();
  const [buyCertificates, { isLoading }] = useBuyCertificates(selectedShop, amounts);

  const [isOfferAgreementApproved, setIsOfferAgreementApproved] = useState(false);

  const totalAmount = useMemo(() => amounts.reduce((a, c) => a + c.value * c.count, 0), [amounts]);
  const isApproveDisabled = !selectedShop || totalAmount <= 0 || !isOfferAgreementApproved;

  return {
    isApproveDisabled,
    totalAmount,
    isLoading,

    amounts,
    selectedShop,
    shopOptions,

    actions: {
      buyCertificates,
      selectShop: actions.selectShop,
      changeAmount: actions.changeAmount,
      setIsOfferAgreementApproved,
    },
  };
};

export const ModalBuyCertificates: React.FC<ModalBuyCertificatesProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const { isApproveDisabled, isLoading, shopOptions, selectedShop, totalAmount, amounts, actions } =
    useComponentState();

  const icon = useGetIconOptimized('circle-man-with-laptop-gradient');

  return (
    <ModalBasic
      isVisible={isVisible}
      imageHeading={{ optimized: icon }}
      title="buy-certs-to-use-later"
      secondaryBtnLabel="cancel-2"
      approveBtnLabel="pay"
      onClose={onClose}
      onApprove={actions.buyCertificates}
      isApproveBtnDisabled={isApproveDisabled}
      isLoading={isLoading}
    >
      <p className="text-xs md:text-base mb-6">{t('buy-certs-desc')}</p>

      <PrimaryDropdown
        label="integrations"
        className="mb-12"
        options={shopOptions}
        value={selectedShop}
        onChange={actions.selectShop}
      />

      {selectedShop && (
        <>
          <div className="mb-7 mx-3">
            <CertificatesAmountInput amounts={amounts} onAmountChange={actions.changeAmount} />
          </div>

          <div className="mb-6 py-2 px-4 bg-black-7 text-base text-black font-medium">
            {t('total')}: {totalAmount} {t('uah')}
          </div>

          <ShopOfferAgreementCheckbox
            id={selectedShop.name + '-offer-agreement'}
            shop={selectedShop}
            onIsApprovedChange={actions.setIsOfferAgreementApproved}
          />
        </>
      )}
    </ModalBasic>
  );
};
