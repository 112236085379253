import React, { useCallback } from 'react';
import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';

import { ApplicationCardItemProps } from './types';
import { SantaLetterApplicationCardItemDetails } from './SantaLetterApplicationCardItemDetails';

type ApplicationCardSelectableItemDetailsProps = ApplicationCardItemProps & {
  isChecked: boolean;
  onChange: (id: number) => void;
};

export const ApplicationCardSelectableItemDetails = (props: ApplicationCardSelectableItemDetailsProps) => {
  if (!props.details || typeof props.details !== 'object') {
    return null;
  }

  const { id, isChecked, onChange, details } = props;

  const itemClickHandler = useCallback(() => {
    onChange(id);
  }, [id]);

  return (
    <div className="flex cursor-pointer mt-2" onClick={itemClickHandler}>
      <CheckboxNotFormField name="test" isChecked={isChecked} className="w-[24px] h-[24px] mr-3 mt-[4px] shrink-0" />

      <SantaLetterApplicationCardItemDetails details={details} />
    </div>
  );
};
