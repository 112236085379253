import { useFilters } from './useFilters';

import { volunteerActions } from '@store/slices/volunteer/slice';
import { useAppDispatch } from '@store/hooks';

import { HowToHelpType } from '@shared/enums';
import { useSyncStateQueryParam } from '@shared/hooks/useSyncStateQueryParam';

import { useFiltersHandlers } from './useFiltersHandlers';

export function useSyncVolunteerStateQueryParams() {
  const filters = useFilters();
  const dispatch = useAppDispatch();

  const { onShopFilterChange } = useFiltersHandlers();

  useSyncStateQueryParam('category', filters.category, {
    defaultValue: HowToHelpType.OnlineMedicaments,
    validate: (value) => !!value && Object.values(HowToHelpType).includes(value as HowToHelpType),
    onStateUpdate: (value) =>
      dispatch(volunteerActions.setCategoryFilter((value as HowToHelpType) || HowToHelpType.OnlineMedicaments)),
  });

  useSyncStateQueryParam('shop', filters.shop, {
    defaultValue: null,
    serialize: (value) => value,
    onStateUpdate: (value) => onShopFilterChange(value),
  });
}
