import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const BlockIcon: React.FC<IconProps> = ({ width = 16, height = 13, className }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1.325 6.325 6.5 8.65c.45.225.975.3 1.5.3s.975-.075 1.5-.3l5.175-2.325c.525-.225.825-.75.825-1.35 0-.6-.3-1.05-.825-1.35L9.5 1.3c-.975-.45-2.025-.45-2.925 0l-5.25 2.4C.8 3.925.5 4.45.5 4.975c0 .6.3 1.125.825 1.35Zm5.85-3.675a2.289 2.289 0 0 1 1.725 0L14 4.975 8.9 7.3c-.525.225-1.2.225-1.725 0l-5.1-2.325 5.1-2.325Z"
      fill="#000"
    />
    <path
      d="M14 8.425 8.825 10.75c-.525.225-1.2.225-1.725 0L2 8.425c-.375-.15-.825 0-.975.375-.15.375 0 .825.375.975l5.1 2.4c.45.225.975.3 1.5.3s.975-.075 1.5-.3l5.175-2.325c.375-.15.525-.6.375-.975-.225-.45-.675-.6-1.05-.45Z"
      fill="#000"
    />
  </svg>
);

export default BlockIcon;
