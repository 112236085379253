import React from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '@hooks/useToggle';

import { AccordionBasic } from '@shared/accordion/AccordionBasic';
import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';

import { AdditionalStatusFilters } from '@shared/enums';

import { CABINET_TAB_NEW } from '../constants';

import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';
import { useIsVisibleGuard } from '../modals/hooks/useIsVisibleGuard';

const filters = [
  {
    name: AdditionalStatusFilters.WithoutReceivedHelp,
    label: 'no-yet-received-help',
  },
  {
    name: AdditionalStatusFilters.IdGovUaVerified,
    label: 'igu-verified',
  },
];

export const AdditionalFilters = () => {
  const { t } = useTranslation();

  const { additional: selected = [] } = useFilters();
  const { onAdditionalFilterChange } = useFiltersHandlers();
  const [isAccordionOpen, handleToggleAccordion] = useToggle({ defaultValue: true });

  const handleFilterToggle = (filter: AdditionalStatusFilters) => {
    onAdditionalFilterChange(filter);
  };

  const isVisible = useIsVisibleGuard({
    tab: [CABINET_TAB_NEW],
  });
  if (!isVisible) {
    return null;
  }

  return (
    <AccordionBasic label="additional" isOpen={isAccordionOpen} onClick={handleToggleAccordion} className="mt-6">
      {filters.map(({ name, label }) => (
        <CheckboxNotFormField
          key={name}
          name={name}
          label={t(label)}
          isChecked={selected.includes(name)}
          onChange={() => handleFilterToggle(name)}
          className="mt-5 first:mt-0"
        />
      ))}
    </AccordionBasic>
  );
};
