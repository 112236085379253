import { useToggleState } from '@shared/hooks/useToggleState';
import { Button } from '@shared/ui/buttons/Button';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ApplicationOnlineHelpModal } from '../volunteer/modals/ApplicationOnlineHelpModal';
import { ModalGratitudeOnline } from '../volunteer/modals/ModalGratitudeOnline';
import { Application } from './types';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../volunteer/modals/hooks/useActiveTab';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import { useUser } from '@shared/hooks/useUser';
import { Routes } from '@shared/enums';
import { useNavigate } from '@hooks/useNavigate';
import { useFilters } from '../volunteer/modals/hooks/useFilters';
import { useUnusedCertificates } from '../volunteer/modals/hooks/useUnusedCertificates';

export type HelpOnlineButtonProps = {
  application: Application;
};

function useIsApplicationCanUseBoughtCertificates(application: Application) {
  const { category } = useFilters();
  const { bought } = useUnusedCertificates();

  return bought.some((certificate) =>
    application.shops.find(({ name, type }) => name === certificate.provider && type === category),
  );
}

function useHelpOnlineButtonState({ application }: HelpOnlineButtonProps) {
  const { category } = useFilters();
  const isApplicationCanUseBoughtCertificates = useIsApplicationCanUseBoughtCertificates(application);

  const [isVisible, toggleIsVisible] = useToggleState(false);
  const [isGratitudeVisible, toggleIsGratitudeVisible] = useToggleState(false);
  const [_, setActiveTab] = useActiveTab();

  const searchParams = useSearchParams();
  const [user] = useUser();
  const navigate = useNavigate();

  const onSuccessHandler = () => {
    toggleIsGratitudeVisible();

    /**
     * TODO: Invalidate cache
     */
    toggleIsVisible();
  };

  const buttonClickHandler = useCallback(() => {
    toggleIsVisible();
  }, [toggleIsVisible]);

  return {
    buttonClickHandler,
    isApplicationCanUseBoughtCertificates,
    modalProps: { application, category, isVisible, onClose: buttonClickHandler, onSuccess: onSuccessHandler },
    gratitudeModalProps: {
      isVisible: isGratitudeVisible,
      onClose: toggleIsGratitudeVisible,
      bonus: parseInt(searchParams?.get('bonus')!, 10) || 0,
      onApprove: () => {
        if (isVisible) {
          toggleIsGratitudeVisible();
        }

        if (user?.isMember) {
          setActiveTab('processed');
        } else {
          navigate(Routes.VolunteerSignUp);
        }
      },
      variant: user?.isMember ? 'for-members' : 'for-guests',
    },
  } as const;
}

export const HelpOnlineButton = (props: HelpOnlineButtonProps) => {
  const { buttonClickHandler, isApplicationCanUseBoughtCertificates, modalProps, gratitudeModalProps } =
    useHelpOnlineButtonState(props);
  const { t } = useTranslation();

  return (
    <>
      <Button
        as="button"
        size="medium"
        variant="rich-blue"
        className={clsx(
          '!rounded-3xl',
          isApplicationCanUseBoughtCertificates && 'bg-bulk-certificates-gradient text-black',
        )}
        onClick={buttonClickHandler}
      >
        {t('give-help')}
      </Button>
      <ApplicationOnlineHelpModal {...modalProps} />
      <ModalGratitudeOnline {...gratitudeModalProps} />
    </>
  );
};
