import React, { useEffect } from 'react';

import { isOnlineMode } from '@shared/utils/applications';
import { ActiveOnlineApplicationCard } from '@widgets/cabinet/applicationCard/ActiveOnlineApplicationCard';
import { ActiveApplicationCard } from '@widgets/cabinet/applicationCard/ActiveApplicationCard';
import { PerformOnlineApplicationCard } from '@widgets/cabinet/applicationCard/PerformOnlineApplicationCard';
import { PerformApplicationCard } from '@widgets/cabinet/applicationCard/PerformApplicationCard';
import { CompleteOnlineApplicationCard } from '@widgets/cabinet/applicationCard/CompleteOnlineApplicationCard';
import { CompleteApplicationCard } from '@widgets/cabinet/applicationCard/CompleteApplicationCard';
import { VerificationApplicationCard } from '@widgets/cabinet/applicationCard/VerificationApplicationCard';
import { EmptyApplicationCard } from '@widgets/cabinet/applicationCard/EmptyApplicationCard';
import { useVolunteerApplicationsParams } from '../modals/hooks/useVolunteerApplicationsParams';
import { useGetApplicationsQuery } from '@store/api/applications.api';
import { SkeletonApplicationCard } from '@widgets/cabinet/applicationCard/SkeletonCard';

import { useVolunteerMode } from '../modals/hooks/useVolunteerMode';
import { VolunteerMode } from '@shared/enums';
import { useGetMySendContactRequestQuery } from '@store/api/contact-request.api';
import { useDispatch } from 'react-redux';
import { applicationsActions } from '@store/slices/applications/slice';

function getCardComponent(type: string, mode: VolunteerMode) {
  const isOnline = isOnlineMode(mode);

  switch (type) {
    case 'new':
      return isOnline ? ActiveOnlineApplicationCard : ActiveApplicationCard;

    case 'in_progress':
      return VerificationApplicationCard;

    case 'processed':
      return isOnline ? PerformOnlineApplicationCard : PerformApplicationCard;

    case 'completed':
      return isOnline ? CompleteOnlineApplicationCard : CompleteApplicationCard;

    default:
      return null;
  }
}

export function VolunteerApplications() {
  const dispatch = useDispatch();
  const [mode] = useVolunteerMode();
  const params = useVolunteerApplicationsParams();
  const { data = { items: [] }, isLoading, isFetching } = useGetApplicationsQuery(params);

  const { isLoading: isCRLoading, isFetching: isCRFetching } = useGetMySendContactRequestQuery();

  const loading = isLoading || isFetching || isCRLoading || isCRFetching;

  useEffect(() => {
    if (params.state !== 'new') {
      dispatch(applicationsActions.removeAll());
    }
  }, [params.state]);

  if (loading) {
    return (
      <div className="flex flex-col space-y-6">
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonApplicationCard key={index} />
        ))}
      </div>
    );
  }

  const CardComponent = getCardComponent(params.state, mode);
  if (!CardComponent) return null;

  return (
    <div className="flex flex-col space-y-6">
      {data.items.length <= 0 && <EmptyApplicationCard />}
      {data.items.map((item: any) => (
        <CardComponent key={item.id} {...item} haveContact={false} />
      ))}
    </div>
  );
}
