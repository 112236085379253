import React from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '@hooks/useToggle';

import { useShops } from '@hooks/useShops';
import { VolunteerMode } from '@shared/enums';

import { AccordionBasic } from '@shared/accordion/AccordionBasic';
import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';

import { CABINET_TAB_NEW } from '../constants';

import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';
import { useIsVisibleGuard } from '../modals/hooks/useIsVisibleGuard';

export const ShopFilters = () => {
  const { t } = useTranslation();

  const [isAccordionOpen, handleToggleAccordion] = useToggle({ defaultValue: true });

  const { category, shop } = useFilters();
  const { onShopFilterChange } = useFiltersHandlers();
  const shops = useShops(category);

  const handleShopSelect = (name: string) => {
    onShopFilterChange(shop === name ? null : name);
  };

  const isVisible = useIsVisibleGuard({
    tab: [CABINET_TAB_NEW],
    mode: [VolunteerMode.Online],
  });
  if (shops.length <= 0 || !isVisible) {
    return null;
  }

  return (
    <AccordionBasic label="shop-partners" isOpen={isAccordionOpen} onClick={handleToggleAccordion}>
      {shops.map(({ name, label }) => (
        <CheckboxNotFormField
          key={name}
          name={name}
          label={t(label)}
          isChecked={shop === name}
          onChange={() => handleShopSelect(name)}
          className="mt-5 first:mt-0"
        />
      ))}
    </AccordionBasic>
  );
};
