import { ApplicantType } from '@shared/enums';

export const volunteerWhoNeedHelpFilters = [
  {
    eventKey: '',
    label: 'all',
    isActive: true,
  },
  {
    eventKey: ApplicantType.LegalEntity,
    label: 'types.organization',
    isActive: false,
  },
  {
    eventKey: ApplicantType.HumanBeing,
    label: 'types.person',
    isActive: false,
  },
];
