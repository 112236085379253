import React from 'react';
import SimpleImageViewer from 'react-simple-image-viewer';

interface ImageViewerProps {
  image: string;

  isVisible: boolean;

  onHide: () => void;
}

export const ImageViewer: React.FC<ImageViewerProps> = ({ image, isVisible, onHide }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <SimpleImageViewer
      src={[image]}
      currentIndex={0}
      closeOnClickOutside
      disableScroll
      backgroundStyle={{ zIndex: 9999, marginLeft: 0, marginTop: 0, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
      onClose={onHide}
    />
  );
};
