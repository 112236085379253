import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const ShareIcon: React.FC<IconProps> = ({ width = 16, height = 14, className }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M7.75 9.5h-1.5a6.749 6.749 0 0 0-5.976 3.607A7.5 7.5 0 0 1 7.75 5V.875l7.875 6.375-7.875 6.375V9.5ZM6.25 8h3v2.481l3.99-3.231-3.99-3.231V6.5h-1.5a5.986 5.986 0 0 0-4.543 2.08A8.24 8.24 0 0 1 6.25 8Z"
        fill="#000"
      />
    </svg>
  );
};
