import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { IconNeedList } from '@shared/icons/IconNeedList';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationItem, Purchase } from './types';

type ApplicationCardAmountOfItemsProps = {
  amount: ApplicationItem['amount'];
  label?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function usePerformOnlineApplicationCardAmountState(props: ApplicationCardAmountOfItemsProps) {
  return {
    amount: props.amount,
    label: props.label ?? 'total-needs',
  } as const;
}

export const PerformOnlineApplicationCardAmount = (props: ApplicationCardAmountOfItemsProps) => {
  const { amount, label } = usePerformOnlineApplicationCardAmountState(props);
  const { t } = useTranslation();
  const iconOptimized = useGetIconOptimized('help-online');

  return (
    <div className={props.className}>
      <div className="text-black-50 text-xxs mb-2">{t(label)}</div>

      <div className="flex items-center text-lg">
        <GatsbyImage image={getImage(iconOptimized)} alt="icon" className="mr-3 w-[26px] h-auto shrink-0" />
        <IconNeedList className="mr-3 shrink-0" />({amount})
      </div>
    </div>
  );
};
