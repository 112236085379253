import React from 'react';
import { useTranslation } from 'react-i18next';

import { CertificateAmount } from '@store/api/types';

interface CertificatesAmountProps {
  amounts: CertificateAmount[];
}

const useComponentState = ({ amounts }: CertificatesAmountProps) => {
  return {
    amounts,
  };
};

export const CertificatesAmount: React.FC<CertificatesAmountProps> = (props) => {
  const { t } = useTranslation();
  const { amounts } = useComponentState(props);

  return (
    <div className="flex flex-col space-y-6">
      {amounts.map(({ value, count }) => (
        <div key={value} className="flex justify-between">
          <span className="text-base text-black font-normal">
            {value} {t('uah')}
          </span>
          <span>
            {count} {t('pc')}
          </span>
        </div>
      ))}
    </div>
  );
};
