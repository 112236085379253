import config, { AppMode } from '@config';
import { useEffect } from 'react';

export function useFBTrackViewContent() {
  useEffect(() => {
    if (typeof window !== 'undefined' && config.mode === AppMode.Production) {
      if (window.fbq != null) {
        window.fbq('track', 'ViewContent');
      }
    }
  }, []);
}
