import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Element } from '@shared/interfaces/element.interface';

interface Icon {
  image?: any;
  src?: string;
  component?: any;
  className?: string;
}

interface ChipChoiceProps<T> extends Element {
  isActive: boolean;
  eventKey: T;
  label: string;
  emoji?: string;
  icon?: Icon;
  iconClassName?: string;
  onClick: (key: T) => void;
}

export const ChipChoice = <T extends string>(props: ChipChoiceProps<T>) => {
  const { isActive, eventKey, label, emoji, icon, className = '', onClick } = props;
  const { t } = useTranslation();
  const IconComponent = icon?.component;

  const buttonCn = clsx(
    'flex justify-center items-center h-[50px] pl-[14px] pr-[20px] text-sm text-black border-2 border-solid rounded-3xl transition-colors lg:text-base lg:h-14',
    isActive
      ? 'border-black hover:bg-black-7 active:bg-black-14'
      : 'bg-black-7 border-transparent hover:bg-black-14 active:bg-black/20',
    className,
  );

  return (
    <button className={buttonCn} onClick={() => onClick(eventKey)}>
      {icon && (
        <span className="flex justify-center w-[27px] mr-[9px] lg:w-[40px] lg:mr-[10px]">
          {icon.image && <GatsbyImage image={getImage(icon.image)} alt="icon" className={icon.className} />}
          {icon.src && <img src={icon.src} alt="icon" className={icon.className} />}
          {IconComponent && <IconComponent className={icon.className} />}
        </span>
      )}
      {(emoji ? emoji + ' ' : '') + t(label) }
    </button>
  );
};
