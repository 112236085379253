import React, { useMemo } from 'react';

import { GenericLink } from '@shared/ui/GenericLink';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';

import { BadgeVariant } from '@values/legion-badges';

import { useLanguage } from '@hooks/useLanguage';
import useSocialShareLinks from '@shared/hooks/useSocialShareLinks';

type ModalShareLegionProps = Pick<ModalBasicProps, 'isVisible' | 'onClose'> & { variant: BadgeVariant };

const useComponentState = (variant: BadgeVariant) => {
  const language = useLanguage();

  const url = useMemo(() => {
    try {
      const baseUrl = new URL(window.location.href);

      baseUrl.pathname = `/${language}/legions/${variant.text}`;
      baseUrl.search = '';

      return baseUrl.toString();
    } catch {
      return `https://social.edopomoga.gov.ua/${language}/legions/${variant.text}`;
    }
  }, [window.location.href]);

  const { buttons } = useSocialShareLinks(url);

  return {
    buttons,
  };
};

export const ModalShareLegion: React.FC<ModalShareLegionProps> = ({ variant, isVisible, onClose }) => {
  const { buttons } = useComponentState(variant);

  const socialButtons = useMemo(
    () =>
      buttons.map((button) => (
        <GenericLink key={button.link} type="outer" to={button.link}>
          <button.icon className="text-black" stroke="black" width={48} height={48} />
        </GenericLink>
      )),
    [buttons],
  );

  return (
    <ModalBasic
      title="Розкажи друзям, що допомагати легко!"
      hasHeaderCloseBtn
      isVisible={isVisible}
      onClose={onClose}
      onApprove={() => {}}
    >
      <div className="flex justify-center space-x-5 mt-5">{socialButtons}</div>
    </ModalBasic>
  );
};
