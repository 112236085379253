import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconFilter } from '@shared/icons/IconFilter';
import { Button } from '@shared/ui/buttons/Button';
import { ButtonClose } from '@shared/ui/buttons/ButtonClose';

import { CABINET_TAB_NEW, CABINET_TAB_PERFORM } from '../volunteer/constants';
import { useFiltersVisibility } from './useFiltersVisibility';
import { useIsVisibleGuard } from '../volunteer/modals/hooks/useIsVisibleGuard';
import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';

interface CabinetFiltersProps {
  children: React.ReactNode;

  before?: React.ReactNode;
}

export const CabinetFilters: React.FC<CabinetFiltersProps> = ({ before, children }) => {
  const { t } = useTranslation();

  const isSmallScreen = useMaxWidthMediaQuery('xl');
  const [isFiltersVisible, toggleFiltersVisibility] = useFiltersVisibility({ defaultVisibility: false });
  const sectionFiltersCn = clsx(
    'fixed left-0 top-0 right-0 bottom-0 z-overlay flex flex-col py-6 px-4 bg-white overflow-y-scroll xl:block xl:static xl:overflow-visible xl:bg-transparent xl:basis-[388px] xl:flex-shrink-0 xl:p-0 xl:mr-8 xl:z-0',
    isFiltersVisible ? 'visible' : 'hidden',
  );

  useEffect(() => {
    const bodyEl = document.body;

    if (isFiltersVisible && isSmallScreen) {
      bodyEl.classList.add('modal-open');
    } else {
      bodyEl.classList.remove('modal-open');
    }
  }, [isFiltersVisible, isSmallScreen]);

  const isVisible = useIsVisibleGuard({
    tab: [CABINET_TAB_NEW],
  });

  if (!isVisible) return <div className="xl:basis-[388px] xl:flex-shrink-0 xl:p-0 xl:mr-8">&nbsp;</div>;

  return (
    <>
      <div className="flex justify-center xl:hidden mb-5 xl:mb-0">
        <Button
          as="button"
          variant="secondary"
          size="medium"
          children={t('search-filters')}
          icon={IconFilter}
          iconClassName="inline-block w-4 mr-2"
          onClick={toggleFiltersVisibility}
        />
      </div>
      {isFiltersVisible && (
        <div className={sectionFiltersCn}>
          {before}
          <div className="flex justify-between items-center xl:block mt-3">
            <h4 className="text-2xl">{t('search')}</h4>
            <ButtonClose onClick={toggleFiltersVisibility} className={'xl:hidden -mr-2'} />
          </div>
          {children}
          <div className="flex justify-center mt-auto pt-4 xl:hidden">
            <Button as="button" size="small" onClick={toggleFiltersVisibility}>
              {t('futher')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
