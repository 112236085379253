import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalBasic } from '@shared/ui/modal/ModalBasic';

import { Shop, useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

import { CertificateAmount } from '@store/api/types';
import { countBy } from '@shared/utils/arrays';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

import { useUnusedCertificates } from './hooks/useUnusedCertificates';
import { CertificatesAmount } from '../content/CertificatesAmount';

interface ModalMyCertificatesProps {
  isVisible: boolean;

  onClose: () => void;

  onApprove: (shop: Shop) => void;

  onReject: () => void;
}

const useShopActions = () => {
  const { t } = useTranslation();

  const integrationConfigs = useIntegrationConfigs();
  const { bought } = useUnusedCertificates();

  const shopOptions = useMemo(() => {
    const availableShops = new Set(bought.map((i) => i.provider));
    const shops = integrationConfigs.flatMap((config) => config.shops.filter((shop) => availableShops.has(shop.name)));

    return shops.map((shop) => ({ label: t(shop.label), value: shop }));
  }, [integrationConfigs, bought]);

  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [amounts, setAmounts] = useState<CertificateAmount[]>([]);

  const selectShop = (shop: Shop | null) => {
    setSelectedShop(shop || null);

    if (shop) {
      const shopCertificates = bought.filter((certificate) => certificate.provider === shop.name);

      setAmounts(countBy(shopCertificates, (item) => item.amount));
    } else {
      setAmounts([]);
    }
  };

  const totalAmount = useMemo(() => amounts.reduce((a, c) => a + c.value * c.count, 0), [amounts]);

  return {
    totalAmount,
    amounts,

    selectedShop,
    shopOptions,

    actions: {
      selectShop,
    },
  };
};

const useComponentState = ({ isVisible, onClose, onApprove, onReject }: ModalMyCertificatesProps) => {
  const { totalAmount, shopOptions, selectedShop, amounts, actions } = useShopActions();

  const onModalApprove = () => {
    if (!selectedShop) {
      return;
    }

    onApprove(selectedShop);
  };

  return {
    isVisible,
    onClose,
    onApprove: onModalApprove,
    onReject,

    amounts,
    totalAmount,
    isApproveDisabled: !selectedShop,

    selectedShop,
    shopOptions,

    actions: {
      selectShop: actions.selectShop,
    },
  };
};

export const ModalMyCertificates: React.FC<ModalMyCertificatesProps> = (props) => {
  const { t } = useTranslation();
  const {
    isVisible,
    onClose,
    onApprove,
    onReject,
    isApproveDisabled,
    shopOptions,
    selectedShop,
    totalAmount,
    amounts,
    actions,
  } = useComponentState(props);

  const icon = useGetIconOptimized('circle-man-with-laptop-gradient');

  return (
    <ModalBasic
      isVisible={isVisible}
      imageHeading={{ optimized: icon }}
      title="my-certs"
      secondaryBtnLabel="buy-more"
      approveBtnLabel="use-now"
      onClose={onClose}
      isApproveBtnDisabled={isApproveDisabled}
      onSecondaryBtnClick={onReject}
      onApprove={onApprove}
    >
      <p className="mb-6">{t('my-certs-desc')}</p>

      <PrimaryDropdown
        label="integrations"
        className="mb-12"
        options={shopOptions}
        value={selectedShop}
        onChange={actions.selectShop}
      />

      {selectedShop && (
        <>
          <div className="mb-7 mx-3">
            <CertificatesAmount amounts={amounts} />
          </div>

          <div className="mb-6 py-2 px-4 bg-black-7 text-base text-black font-medium">
            {t('total')}: {totalAmount} {t('uah')}
          </div>
        </>
      )}
    </ModalBasic>
  );
};
