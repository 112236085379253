import React from 'react';

import { Pagination } from '@shared/ui/Pagination';

import { useGetTotalActiveApplicationsQuery } from '@store/api/applications.api';
import { useVolunteerApplicationsParams } from './modals/hooks/useVolunteerApplicationsParams';
import { useVolunteerPagination } from './modals/hooks/useVolunteerPagination';

export function VolunteerPagination() {
  const pagination = useVolunteerPagination();
  const params = useVolunteerApplicationsParams();

  const { page, ...other } = params;
  const { data } = useGetTotalActiveApplicationsQuery(other);

  return (
    <Pagination
      initialPage={pagination.initialPage}
      onPageChange={pagination.onPageChange}
      pageCount={data?.totalPages || 0}
    />
  );
}
