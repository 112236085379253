import { useEffect } from 'react';

import { VolunteerMode } from '@shared/enums';
import { isOnlineMode } from '@shared/utils/applications';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

import { useVolunteerMode } from './modals/hooks/useVolunteerMode';

export function useVolunteerSetHowToHelp() {
  const isUkLanguage = useIsSpecificLanguage('uk');
  const [mode, setMode] = useVolunteerMode();

  useEffect(() => {
    if (!isUkLanguage && !isOnlineMode(mode)) {
      setMode(VolunteerMode.Online);

      return;
    }
  }, [mode, isUkLanguage]);

  return;
}
