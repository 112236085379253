import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';

import { ProgressBar } from '@shared/ui/progress-bars/base';
import { GenericLink } from '@shared/ui/GenericLink';

import { badgeBreakpoints, badgeVariants } from '@values/legion-badges';
import { Routes } from '@shared/enums';

import { ShareButton } from './share-button';
import { ImageViewer } from '@shared/viewers/image-viewer';

interface LegionBadgeProps extends Element {
  progress: number;
}

const getBadgeVariant = (value: number) => {
  for (let breakpoint of badgeBreakpoints.slice().reverse()) {
    if (value >= breakpoint) {
      return badgeVariants[breakpoint];
    }
  }

  return null;
};

const useComponentState = ({ progress, className }: LegionBadgeProps) => {
  const [isFullBadgeVisible, setIsFullBadgeVisible] = useState(false);

  const showFullBadge = () => setIsFullBadgeVisible(true);
  const hideFullBadge = () => setIsFullBadgeVisible(false);

  return {
    isFullBadgeVisible,
    progress,
    className,
    variant: getBadgeVariant(progress),
    actions: { showFullBadge, hideFullBadge },
  };
};

export const LegionBadge: React.FC<LegionBadgeProps> = React.memo((props) => {
  const { t } = useTranslation();
  const { actions, isFullBadgeVisible, progress, className, variant } = useComponentState(props);

  if (!variant) {
    return null;
  }

  return (
    <div className={clsx('pt-4 px-4 pb-6 bg-white rounded-lg', className)}>
      <div className={clsx('relative flex items-center h-[80px] mb-2 p-2 rounded-md', variant.background)}>
        <div
          className={clsx(
            'after:absolute after:top-2 after:left-2 after:h-16 after:w-16 after:bg-no-repeat after:bg-center after:bg-full-size after:rounded-full after:transition-all cursor-pointer',
            variant.badge,
          )}
          onClick={actions.showFullBadge}
        >
          <span className="visually-hidden">{variant.text}</span>
        </div>

        <p className="font-normal text-xs ml-[74px]">
          {t('welcome-legion', { legion: t(variant.text) })}{' '}
          <GenericLink type="page" to={Routes.LegionInstruction} className="font-bold underline">
            {t('read-more')}
          </GenericLink>
        </p>
      </div>

      <div className="mx-3 mb-3">
        <span className="block mb-1 text-base font-normal text-shamrock">{progress}</span>

        <ProgressBar value={progress} breakpoints={badgeBreakpoints} />
      </div>

      <ShareButton variant={variant} />

      <ImageViewer image={variant.image} isVisible={isFullBadgeVisible} onHide={actions.hideFullBadge} />
    </div>
  );
});
