import { useDispatch, useSelector } from 'react-redux';

import { selectActiveTab } from '@store/slices/volunteer/selectors';
import { volunteerActions } from '@store/slices/volunteer/slice';

export function useActiveTab() {
  const dispatch = useDispatch();

  return [useSelector(selectActiveTab), (tab: string) => dispatch(volunteerActions.changeActiveTab(tab))] as const;
}
