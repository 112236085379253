import bronzeBadge from '@assets/images/badges/bronze.jpg';
import silverBadge from '@assets/images/badges/silver.jpg';
import goldBadge from '@assets/images/badges/gold.jpg';
import diamantBadge from '@assets/images/badges/diamant.jpg';
import sapphireBadge from '@assets/images/badges/sapphire.jpg';

export interface BadgeVariant {
  badge: string;
  background: string;
  text: string;
  image: string;
}

export const badgeVariants: Record<number, BadgeVariant> = {
  5: {
    badge: 'after:bg-badge-bronze',
    background: 'bg-bronze',
    text: 'bronze',
    image: bronzeBadge,
  },
  30: {
    badge: 'after:bg-badge-silver',
    background: 'bg-silver',
    text: 'silver',
    image: silverBadge,
  },
  100: {
    badge: 'after:bg-badge-gold',
    background: 'bg-gold',
    text: 'gold',
    image: goldBadge,
  },
  250: {
    badge: 'after:bg-badge-sapphire',
    background: 'bg-sapphire',
    text: 'sapphire',
    image: sapphireBadge,
  },
  450: {
    badge: 'after:bg-badge-diamant',
    background: 'bg-diamant',
    text: 'diamant',
    image: diamantBadge,
  },
};

export const badgeBreakpoints = Object.keys(badgeVariants)
  .map((variant) => parseInt(variant, 10))
  .sort((a, b) => a - b);
