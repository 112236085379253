import React, { useCallback, useState } from 'react';
import { ApplicationCardProps } from './types';
import { useProlongApplicationItemsMutation } from '@store/api/applications.api';
import { Button } from '@shared/ui/buttons/Button';
import { useTranslation } from 'react-i18next';
import { isSameDay, addDays, isAfter } from 'date-fns';
import config from '@config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type VolunteerHandToHandChangeDateProps = Pick<ApplicationCardProps, 'items' | 'inProgressAt' | 'inProgressTo'>;

function useVolunteerHandToHandChangeDateState(props: VolunteerHandToHandChangeDateProps) {
  const { inProgressAt, inProgressTo, items } = props;

  const { t } = useTranslation();

  const inProgressToDate = new Date(inProgressTo);

  const [date, setDate] = useState<Date>(inProgressToDate);

  const isSameSelected = isSameDay(inProgressToDate, date);

  const onChange = useCallback((date: Date) => {
    setDate(date);
  }, []);

  const inProgressAtDate = new Date(inProgressAt);

  const currentDate = new Date();

  const defaultInProgressToDate = addDays(inProgressAtDate, config.appItems.defaultInProgressToDays);

  const minDate = isAfter(currentDate, defaultInProgressToDate) ? currentDate : defaultInProgressToDate;

  const maxDate = addDays(inProgressAtDate, config.appItems.maxInProgressToDays);

  const [prolongMutation, { isLoading }] = useProlongApplicationItemsMutation();

  const saveClick = () => {
    try {
      prolongMutation({ items, inProgressTo: date.toISOString() });
    } catch (e) {
      console.error(e);
    }
  };

  const isDatepickerDisabled = isAfter(currentDate, inProgressToDate);

  const isSaveButtonDisabled = isSameSelected || isDatepickerDisabled || isLoading;

  const endDateText = t('close-until');

  const saveButtonLabel = t('save');

  return {
    endDateText,
    date,
    onChange,
    minDate,
    maxDate,
    isDatepickerDisabled,
    saveButtonLabel,
    isSaveButtonDisabled,
    saveClick,
  };
}

export const VolunteerHandToHandChangeDate = (props: VolunteerHandToHandChangeDateProps) => {
  const {
    endDateText,
    date,
    onChange,
    minDate,
    maxDate,
    isDatepickerDisabled,
    saveButtonLabel,
    isSaveButtonDisabled,
    saveClick,
  } = useVolunteerHandToHandChangeDateState(props);

  return (
    <div className="flex gap-4 items-center pt-4">
      <div>{endDateText}</div>
      <div className="w-60 overflow-hidden">
        <DatePicker
          className="peer w-full h-[50px] pl-4 pt-3 pb-3 outline-none bg-transparent text-base text-black transition-all border-b-[1px] border-b-solid placeholder:text-black-50 hover:bg-black-7 focus:bg-black-7 cursor-pointer disabled:opacity-20 disabled:cursor-text"
          selected={date}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="dd/MM/yyyy"
          calendarStartDay={1}
          disabled={isDatepickerDisabled}
        />
      </div>
      <Button as="button" size="small" disabled={isSaveButtonDisabled} onClick={saveClick}>
        {saveButtonLabel}
      </Button>
    </div>
  );
};
