import React from 'react';
import { HelpToHandsButton } from '../HelpToHandsButton';
// import { ApplicationsBannerMastercard } from './ApplicationsBannerMastercard';
import { VolunteerApplications } from './VolunteerApplications';

import { UnusedCertificatesBanner } from '../UnusedCertificatesBanner';
import { VolunteerPagination } from '../VolunteerPagination';

export const CabinetVolunteerApplications: React.FC = () => {
  return (
    <div className="min-h-[200px] relative">
      <UnusedCertificatesBanner />
      {/* Hidden as unnecessary */}
      {/* <ApplicationsBannerMastercard /> */}
      <div className="space-y-6">
        <VolunteerApplications />
      </div>
      <VolunteerPagination />
      <HelpToHandsButton className="!fixed left-10 right-10 z-10 bottom-5 max-w-[200px] m-auto shadow-button-overlay md:bottom-10 md:left-auto md:m-0" />
    </div>
  );
};
