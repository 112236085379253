import React from 'react';

import { ProgressBarBreakpoint } from './breakpoint';

interface ProgressBarProps {
  value: number;

  breakpoints: number[];
}

const useComponentState = ({ value, breakpoints }: ProgressBarProps) => {
  const progressBars = breakpoints.map((breakpoint, index) => {
    const [prevBreakpoint, nextBreakpoint] = [breakpoints[index - 1], breakpoints[index + 1]];

    if (!nextBreakpoint) {
      return null;
    }

    return (
      <ProgressBarBreakpoint
        key={`${breakpoint}-${nextBreakpoint}`}
        min={breakpoint}
        max={nextBreakpoint}
        value={value}
        isMinHidden={!!prevBreakpoint}
      />
    );
  });

  return { progressBars };
};

export const ProgressBar: React.FC<ProgressBarProps> = React.memo((props) => {
  const { progressBars } = useComponentState(props);

  return <div className="flex space-x-[1px]">{progressBars}</div>;
});
