import React, { useMemo } from 'react';

import { CabinetLocationFilters } from '@widgets/cabinet/cabinetFilters/CabinetLocationFilters';

import { CABINET_TAB_NEW } from '../constants';
import { useFilters } from '../modals/hooks/useFilters';
import { useFiltersHandlers } from '../modals/hooks/useFiltersHandlers';
import { useIsVisibleGuard } from '../modals/hooks/useIsVisibleGuard';
import { useGetPrettyLocalityName } from '@shared/hooks/usePrettyLocalityName';
import { useTranslation } from 'react-i18next';

export const CabinetVolunteerLocationFilters = ({ regions, localities }) => {
  const { regionId, localityId } = useFilters();
  const { onRegionChange, onLocalityChange } = useFiltersHandlers();

  const getPrettyLocalityName = useGetPrettyLocalityName();
  const { t } = useTranslation();

  const regionOptions = useMemo(
    () => [
      { value: '', label: t('all') },
      ...regions.map((region: any) => ({
        value: region.id,
        label: region.name,
      })),
    ],
    [t, regions],
  );

  const localityOptions = useMemo(
    () => [
      { value: '', label: t('all') },
      ...localities.map((locality: any) => ({
        value: locality.id,
        label: getPrettyLocalityName(locality.name, locality.category, locality.district),
      })),
    ],
    [t, getPrettyLocalityName, localities],
  );

  const regionValue = regionId || '';
  const localityValue = localityId || '';

  const handleRegionChange = (value: string) => {
    const regionId = parseInt(value, 10);

    onRegionChange(regionId ? regionId : null);
  };

  const handleLocalityChange = (value: string) => {
    const localityId = parseInt(value, 10);

    onLocalityChange(localityId ? localityId : null);
  };

  const isVisible = useIsVisibleGuard({
    tab: [CABINET_TAB_NEW],
  });
  if (!isVisible) return null;

  return (
    <CabinetLocationFilters
      regionValue={regionValue}
      localityValue={localityValue}
      regionOptions={regionOptions}
      localityOptions={localityOptions}
      handleRegionChange={handleRegionChange}
      handleLocalityChange={handleLocalityChange}
    />
  );
};
