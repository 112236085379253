import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import BlockIcon from '@shared/icons/block';

import { Option } from '@shared/interfaces/option.interface';
import { Element } from '@shared/interfaces/element.interface';

const enum CertificatesDropdownOption {
  MyCertificates = 'my-certificates',

  BuyCertificates = 'buy-certificates',
}

const getSelectStyles = () => ({
  control: (_: any, state: any) => {
    return {
      display: 'flex',

      minWidth: 170,
      minHeight: 42,

      backgroundImage: 'linear-gradient(87.41deg, #CEB0FF -5.94%, #FFE382 102.46%)',
      borderRadius: 21,

      transition: 'background-color 150ms',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },

      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
      },
    };
  },

  indicatorSeparator: () => ({
    display: 'none',
  }),

  dropdownIndicator: (_: any, state: any) => {
    const isMenuOpen = state.selectProps.menuIsOpen;

    return {
      display: 'flex',
      marginRight: 10,
      color: '#000000',
      transition: 'transform 0.3s ease',
      transform: isMenuOpen && 'rotate(-180deg)',
    };
  },

  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: 'none',
      textOverflow: 'none',
      color: '#000000',
    };
  },

  valueContainer: (provided: object) => {
    return {
      ...provided,

      paddingLeft: '16px',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 400,
    };
  },

  menu: (provided: any, state: any) => {
    return {
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      padding: '16px 0',
      border: 'none',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.2)',
    };
  },

  option: (provided: any, state: any) => {
    const focusedStyles = {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    };

    const disabledStyles = {
      color: 'rgba(0, 0, 0, 0.3)',
    };

    return {
      ...provided,
      padding: '8px 16px',
      color: '#000000',
      fontSize: '13px',
      lineHeight: '18px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      ...(state.isFocused ? focusedStyles : {}),
      ...(state.isDisabled ? disabledStyles : {}),
    };
  },
});

const SingleValue = (props: any) => {
  const { t } = useTranslation();

  return (
    <components.SingleValue {...props}>
      <div className="flex items-center space-x-1 text-xs text-black font-normal">
        <BlockIcon />

        <span>{t('certs')}</span>
      </div>
    </components.SingleValue>
  );
};

interface CertificatesDropdownProps extends Element {
  isMyCertificatesDisabled?: boolean;

  onBuyCertificatesClick: () => void;

  onMyCertificatesClick: () => void;
}

const useComponentState = ({
  className,

  onMyCertificatesClick,
  onBuyCertificatesClick,
  isMyCertificatesDisabled,
}: CertificatesDropdownProps) => {
  const { t } = useTranslation();

  const styles = React.useMemo(getSelectStyles, []);
  const options = React.useMemo(() => {
    return [
      {
        label: t('my-certs'),
        value: CertificatesDropdownOption.MyCertificates,
        isDisabled: isMyCertificatesDisabled,
      },
      {
        label: t('buy'),
        value: CertificatesDropdownOption.BuyCertificates,
        isDisabled: false,
      },
    ];
  }, [t, isMyCertificatesDisabled]);

  const onSelect = React.useCallback(
    (option: Option | null) => {
      if (!option) {
        return;
      }

      if (option.value === CertificatesDropdownOption.BuyCertificates) {
        onBuyCertificatesClick();
      }

      if (option.value === CertificatesDropdownOption.MyCertificates) {
        onMyCertificatesClick();
      }
    },
    [onBuyCertificatesClick, onMyCertificatesClick],
  );

  return {
    className,

    styles,
    options,

    handlers: {
      onSelect,
    },
  };
};

export const CertificatesDropdown: React.FC<CertificatesDropdownProps> = (props) => {
  const { t } = useTranslation();
  const { options, className, styles, handlers } = useComponentState(props);

  return (
    <div className={className}>
      <Select
        defaultValue={{ label: t('certs'), value: '-' }}
        options={options}
        isSearchable={false}
        styles={styles}
        components={{ SingleValue }}
        menuPlacement="bottom"
        onChange={handlers.onSelect}
        // @ts-ignore
        isOptionDisabled={(option) => option.isDisabled}
      />
    </div>
  );
};
