import * as React from 'react';

import { ApplicationCardWhoNeeds } from '@widgets/cabinet/applicationCard/ApplicationCardWhoNeeds';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { ApplicationCardAddress } from '@widgets/cabinet/applicationCard/ApplicationCardAddress';
import { ApplicationCardRemovableItem } from '@widgets/cabinet/applicationCard/ApplicationCardRemovableItem';
import { applicationsActions } from '@store/slices/applications/slice';
import { useAppDispatch } from '@store/hooks';
import { useTranslation } from 'react-i18next';
import { ApplicationCardCollapseController } from '@widgets/cabinet/applicationCard/ApplicationCardCollapseController';
import { useIsCollapsed } from '@hooks/useIsCollapsed';
import clsx from 'clsx';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { isDefaultView } from '@shared/utils/applications';
import { ApplicationCardItemDetails } from '@widgets/cabinet/applicationCard/ApplicationCardItemDetails';

type ApplicationConfirmModalBodyProps = {
  application: Application;
  onRemove?: (id: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const ApplicationConfirmModalBody: React.FC<ApplicationConfirmModalBodyProps> = ({
  application,
  className,
  onRemove,
}) => {
  const { view, customer, items } = application;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();
  const isMediumScreen = useMinWidthMediaQuery('md');

  React.useEffect(() => {
    if (isMediumScreen && isCollapsed) {
      toggleCollapsedHandler();
    }
  }, [isMediumScreen]);

  const itemClickHandler = React.useCallback(
    (itemId: number) => {
      dispatch(applicationsActions.toggleItemIsChecked({ application, itemId }));
    },
    [application.id],
  );

  const ApplicationCardItem = isDefaultView(view) ? ApplicationCardRemovableItem : ApplicationCardItemDetails;

  return (
    <div className={className}>
      <div className="flex flex-col gap-y-6 p-6 md:pr-[5.5rem] border-2 border-hawkes-blue rounded-lg">
        <ApplicationCardAddress address={customer.address} />

        <ApplicationCardWhoNeeds customer={customer} items={items} view={view} className="text-xs" withoutClamp />

        <div>
          <p className="text-black-50 text-xxs mb-2">
            {t('selected')} ({items.length})
          </p>

          <div className="flex justify-center md:hidden">
            <ApplicationCardCollapseController
              className="flex mx-auto"
              onClick={toggleCollapsedHandler}
              isCollapsed={isCollapsed}
              view={view}
            />
          </div>

          <div className={clsx('mt-2 overflow-hidden transition-all duration-500', isCollapsed ? 'h-0' : 'h-full')}>
            {items.map((item) => (
              <ApplicationCardItem key={item.id} {...item} onRemove={onRemove || itemClickHandler} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
