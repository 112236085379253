import clsx from 'clsx';
import React, { useState } from 'react';
import { UserRating } from '../rating/user-rating';
import { ActiveApplicationCardItems } from './ActiveApplicationCardItems';
import { ActiveOnlineApplicationCardItems } from './ActiveOnlineApplicationCardItems';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { ApplicationCardUserReceivedHelp } from './ApplicationCardUserReceivedHelp';
import { ApplicationCardWhoNeeds } from './ApplicationCardWhoNeeds';
import { HelpOnlineButton } from './HelpOnlineButton';
import { ApplicationCardProps } from './types';

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function useActiveOnlineCardState(props: ApplicationCardProps) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: props.id,
    view: props.view,
    purchase: props.purchase,
    customer: props.customer,
    applicationCardAddressProps: {
      address: props.customer.address,
      oauth: props.customer.oauth,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      items: props.items,
      view: props.view,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    helpOnlineButtonProps: {
      application: props,
    },
  } as const;
}

export const ActiveOnlineApplicationCard = (props: ApplicationCardProps) => {
  const {
    customer,
    view,
    helpOnlineButtonProps,
    applicationCardAddressProps,
    applicationCardWhoNeedsProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    collapsedProps,
  } = useActiveOnlineCardState(props);

  return (
    <div className="p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <div className="flex flex-col items-start justify-between w-100 lg:grid lg:grid-cols-5 lg:items-start lg:mb-4 lg:gap-5">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4 lg:mb-0 col-span-2" />

        <UserRating value={customer.rating} className="mb-4 order-first lg:mb-0 lg:order-none" />

        <ApplicationCardCollapseController
          className="hidden lg:flex col-end-[-1] col-span-2 justify-self-end"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col justify-between w-100 lg:grid lg:grid-cols-5 lg:items-start gap-5">
        <ApplicationCardWhoNeeds
          className="col-span-2 self-start"
          {...applicationCardWhoNeedsProps}
          withoutClamp={!collapsedProps.isCollapsed}
        />

        <div className="flex justify-between lg:justify-start col-span-2">
          <ApplicationCardAmount className="lg:mr-10" {...applicationCardAmountProps} />
          <ApplicationCardUserReceivedHelp customer={customer} />
        </div>

        <div className="self-center justify-self-end">
          <HelpOnlineButton {...helpOnlineButtonProps} />
        </div>
      </div>
      <div className="flex justify-center mt-5 lg:mt-0">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <ActiveOnlineApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
