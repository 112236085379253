import { AccordionBasic } from '@shared/accordion/AccordionBasic';
import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import React, { useMemo } from 'react';
import { useToggle } from '@hooks/useToggle';
import { OptionType } from '@shared/types/common.types';
import { useTranslation } from 'react-i18next';

interface CabinetLocationFilters {
  regionValue: string | number;
  localityValue: string | number;
  regionOptions: OptionType[];
  localityOptions: OptionType[];
  handleRegionChange: (key: string) => void;
  handleLocalityChange: (key: string) => void;
}

export const CabinetLocationFilters: React.FC<CabinetLocationFilters> = (props) => {
  const { regionValue, localityValue, regionOptions, localityOptions, handleLocalityChange, handleRegionChange } =
    props;
  const [isAccordionOpen, handleToggleAccordion] = useToggle({ defaultValue: true });

  const { t } = useTranslation();

  const regions = useMemo(
    () => regionOptions.map((item: any) => ({ ...item, label: t(item.label) })),
    [regionOptions, t],
  );

  const localities = useMemo(
    () =>
      localityOptions.map((item: any) => ({
        ...item,
      })),
    [localityOptions],
  );

  return (
    <AccordionBasic label="location" isOpen={isAccordionOpen} onClick={handleToggleAccordion} className="mt-6">
      <PrimaryDropdown value={regionValue} onChange={handleRegionChange} label="region" options={regions} />
      <PrimaryDropdown
        value={localityValue}
        onChange={handleLocalityChange}
        label="locality"
        options={localities}
        className="mt-6"
        searchable
        helperText={t('helper-select-with-search')}
      />
    </AccordionBasic>
  );
};
