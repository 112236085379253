import React from 'react';

import circleWowFaceIcon from '@assets/icons/circle-wow-face.png';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';

import { ModalBodyPhoneIsRequired } from './bodies/ModalBodyPhoneIsRequired';

type ModalPhoneIsRequiredProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove'>;

export const ModalPhoneIsRequired: React.FC<ModalPhoneIsRequiredProps> = ({ isVisible, onClose, onApprove }) => {
  return (
    <ModalBasic
      hasHeaderCloseBtn
      isVisible={isVisible}
      title="phone-number-is-required"
      imgSrc={circleWowFaceIcon}
      approveBtnLabel="go-to-profile"
      onClose={onClose}
      onApprove={onApprove}
    >
      <ModalBodyPhoneIsRequired />
    </ModalBasic>
  );
};
