import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { selectPagination } from '@store/slices/volunteer/selectors';
import { volunteerActions } from '@store/slices/volunteer/slice';
import { useAppSelector } from '@store/hooks';

import { useFilters } from './useFilters';
import { useActiveTab } from './useActiveTab';

export function useVolunteerPagination() {
  const filters = useFilters();
  const [activeTab] = useActiveTab();
  const dispatch = useDispatch();

  const { page, perPage } = useAppSelector(selectPagination);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const onPageChange = useCallback(
    (data: { selected: number }) => {
      dispatch(volunteerActions.setPagination({ perPage, page: data.selected + 1 }));
    },
    [perPage],
  );
  const reset = useCallback(() => dispatch(volunteerActions.resetPagination()), []);

  useEffect(() => {
    reset();
  }, [filters, activeTab]);

  return {
    page,
    reset,
    perPage,
    onPageChange,
    initialPage: page - 1,
  } as const;
}
