import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';
import { isDefaultView } from '@shared/utils/applications';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  isAllItemsInApplicationSelected,
  selectSelectedApplicationItemsIds,
} from '@store/slices/applications/selectors';
import { applicationsActions } from '@store/slices/applications/slice';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationCardItemsTitle } from './ApplicationCardItemsTitle';
import { ApplicationCardSelectableItem } from './ApplicationCardSelectableItem';
import { ApplicationCardSelectableItemDetails } from './ApplicationCardSelectableItemDetails';
import { Application } from './types';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  application: Application;
};

function useActiveApplicationCardItemsState({ application }: Props) {
  const { id, items } = application;
  const selectedApplicationItemIds = useAppSelector((state) => selectSelectedApplicationItemsIds(state, id));

  const memo = useMemo(() => {
    return isAllItemsInApplicationSelected({ applicationId: id, count: items.length });
  }, [id, items.length]);

  const isChecked = useAppSelector(memo);
  const dispatch = useAppDispatch();

  const toggleIsAllChecked = () => {
    if (!isChecked) {
      dispatch(applicationsActions.upsertOne(application));
    } else {
      dispatch(applicationsActions.removeOne(application.id));
    }
  };

  const toggleIsItemChecked = useCallback(
    (itemId: number) => {
      return dispatch(applicationsActions.toggleItemIsChecked({ application, itemId: itemId }));
    },
    [selectedApplicationItemIds],
  );

  const computedItems = items.map((item) => ({
    ...item,
    isChecked: selectedApplicationItemIds.includes(item.id),
  }));

  return {
    items: computedItems,
    isChecked,
    toggleIsChecked: toggleIsAllChecked,
    amount: items.length,
    toggleIsItemChecked,
    view: application.view,
  } as const;
}
export const ActiveApplicationCardItems = (props: Props) => {
  const { items, view, isChecked, toggleIsChecked, amount, toggleIsItemChecked } =
    useActiveApplicationCardItemsState(props);
  const { t } = useTranslation();

  const ApplicationCardItem = isDefaultView(view)
    ? ApplicationCardSelectableItem
    : ApplicationCardSelectableItemDetails;

  return (
    <div className="lg:col-span-3">
      <ApplicationCardItemsTitle view={view} />
      {isDefaultView(view) && (
        <div className="flex items-center cursor-pointer text-xs" onClick={toggleIsChecked}>
          <CheckboxNotFormField name="test" isChecked={isChecked} className="w-[24px] h-[24px] mr-3 mt-[2px]" />
          {t('select-all')} ({amount})
        </div>
      )}
      {items.map((item) => (
        <ApplicationCardItem key={item.id} {...item} onChange={toggleIsItemChecked} />
      ))}
    </div>
  );
};
