import { useUser } from '@shared/hooks/useUser';
import { useGetUserProfileQuery } from '@store/api/user.api';

export function useCanUserTakeApplication(): readonly [boolean, string[]] {
  const { data: profile = null } = useGetUserProfileQuery();
  const [user] = useUser();

  if (!profile || !user?.isMember) return [true, [] as string[]] as const;

  const requiredData = [
    {
      name: 'phone-number',
      value: profile.phoneNumber,
    },
  ];
  const missingData = requiredData.filter((item) => !item.value);

  return [missingData.length === 0, missingData.map((item) => item.name)] as const;
}
