import React from 'react';

import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';

import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ModalBodyHandHelpGuest } from './bodies/ModalBodyHandHelpGuest';

type ModalHandHelpGuestProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove' | 'onSecondaryBtnClick'>;

export const ModalHandHelpGuest: React.FC<ModalHandHelpGuestProps> = ({
  isVisible,
  onClose,
  onSecondaryBtnClick,
  onApprove,
}) => {
  const iconOptimized = useGetIconOptimized('circle-man-with-laptop');
  const imageHeading = {
    optimized: iconOptimized,
  };

  return (
    <ModalBasic
      isVisible={isVisible}
      title="join-team"
      imageHeading={imageHeading}
      secondaryBtnLabel="sign-up"
      approveBtnLabel="help-online"
      onClose={onClose}
      onApprove={onApprove}
      onSecondaryBtnClick={onSecondaryBtnClick}
    >
      <ModalBodyHandHelpGuest />
    </ModalBasic>
  );
};
