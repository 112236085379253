import React from 'react';

import { useUser } from '@shared/hooks/useUser';

import { ModalGratitudeOnline } from './modals/ModalGratitudeOnline';
import { ModalFailedPayment } from './modals/ModalFailedPayment';
import { ModalVisibleEvent, useModalVisible } from './modals/hooks/useModalProps';

import { CABINET_TAB_NEW, CABINET_TAB_PERFORM } from './constants';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import { useNavigate } from '@hooks/useNavigate';
import { Routes } from '@shared/enums';

import { useFBTrackSuccessPayment } from './useFBTrackSuccessPayment';

import { useChangeVolunteerTab } from './modals/hooks/useChangeVolunteerTab';
import { ModalGratitudeBoughtCertificates } from './modals/ModalGratitudeBoughtCertificates';

function useModalProps() {
  const [user] = useUser();
  const navigate = useNavigate();
  useFBTrackSuccessPayment();

  const searchParams = useSearchParams();
  const [isSucceededPaymentVisible, hideSucceededPaymentModal] = useModalVisible(ModalVisibleEvent.HelpOnlineSuccess);
  const [isFailedPaymentVisible, hideFailedPaymentModal] = useModalVisible(ModalVisibleEvent.HelpOnlineError);
  const [isSucceededBoughtCertificatesVisible, hideIsSucceededBoughtCertificatesModal] = useModalVisible(
    ModalVisibleEvent.BulkPurchaseSuccess,
  );

  const changeVolunteerTab = useChangeVolunteerTab();

  return {
    succeededBoughtCertificatesModalProps: {
      isVisible: isSucceededBoughtCertificatesVisible,
      onApprove: hideIsSucceededBoughtCertificatesModal,
      onClose: hideIsSucceededBoughtCertificatesModal,
    },
    failedPaymentModalProps: {
      isVisible: isFailedPaymentVisible,
      onClose: () => hideFailedPaymentModal(),
      onApprove: () => {
        hideFailedPaymentModal();

        return changeVolunteerTab(CABINET_TAB_NEW);
      },
    },
    succeededPaymentModalProps: {
      bonus: parseInt(searchParams?.get('bonus')!, 10) || 0,
      isVisible: isSucceededPaymentVisible,
      onClose: () => {
        hideSucceededPaymentModal();

        return changeVolunteerTab(CABINET_TAB_NEW);
      },
      onApprove: () => {
        if (!user?.isMember) {
          return navigate(Routes.VolunteerSignUp);
        }

        hideSucceededPaymentModal();

        return changeVolunteerTab(CABINET_TAB_PERFORM);
      },
      variant: user?.isMember ? 'for-members' : 'for-guests',
    },
  } as const;
}

export const CabinetModals = () => {
  const { succeededBoughtCertificatesModalProps, failedPaymentModalProps, succeededPaymentModalProps } =
    useModalProps();

  return (
    <>
      <ModalFailedPayment {...failedPaymentModalProps} />
      <ModalGratitudeOnline {...succeededPaymentModalProps} />

      <ModalGratitudeBoughtCertificates {...succeededBoughtCertificatesModalProps} />
    </>
  );
};
